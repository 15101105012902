import Link from 'next/link'
import { Fragment } from 'react'

import UnstyledButton from '@thebeansgroup/ui_ctas/unstyled_button'
import LoadingSpinner from '@thebeansgroup/ui_loaders/loading_spinner'

import { Nullable, NullableReactElement } from '@root/src/typings/utils.js'

import * as styles from './styles'
import { TPionButton } from './types.js'

const PionButton = ({
  children,
  loading = false,
  onClick,
  customStyles,
  type = 'primary',
  disabled = false,
  href = null,
  prefetch = false,
  newTab = false
}: TPionButton): React.ReactElement => {
  const getContent = (): Nullable<React.ReactNode | string> => {
    const variant = type === 'primary' ? 'light' : 'standard'
    if (loading) {
      return (
        <LoadingSpinner
          width='16px'
          height='16px'
          variant={variant}
          label='Loading...'
        />
      )
    }

    return children
  }

  const renderButton = (): NullableReactElement => {
    if (href) return null

    return (
      <UnstyledButton
        css={[styles.pionButton, styles[type], customStyles]}
        onClick={onClick}
        disabled={disabled}
      >
        {getContent()}
      </UnstyledButton>
    )
  }

  const renderLinkButton = (): NullableReactElement => {
    if (!href) return null

    return (
      <Link
        css={[
          styles.pionButton,
          styles[type],
          customStyles,
          disabled && styles.linkDisabled
        ]}
        href={href}
        prefetch={prefetch}
        target={newTab ? '_blank' : '_self'}
        onClick={onClick}
      >
        {getContent()}
      </Link>
    )
  }
  return (
    <Fragment>
      {renderButton()}
      {renderLinkButton()}
    </Fragment>
  )
}

export default PionButton
