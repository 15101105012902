import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = css`
  display: flex;
  gap: 10px;
  border-radius: 10px;
  align-items: center;
  padding: 10px 17px;
  background-color: ${Colours('offBlack', 300)};
`
