/* istanbul ignore file */
import { compile, match } from 'path-to-regexp'

import { ROUTES } from '@constants/routes'

export const getNextRoute = (routeKey, params) => {
  const route = ROUTES[routeKey]
  if (!route) throw new Error(`Undefined route: '${routeKey}'`)
  if (isExternalRoute(route)) return route

  return compile(route)(params)
}

export const isExternalRoute = (route) => route.includes('http')

export const shouldNotRenderCreateBackButton = (router, pageConfig) =>
  !!match(ROUTES[pageConfig[0].pathname])(router.pathname) ||
  !!match(ROUTES[pageConfig[2].pathname])(router.pathname)

export const getCreateActivePageIndex = (router, pageFlowConfig) => {
  return pageFlowConfig.findIndex(
    (page) => !!match(ROUTES[page.pathname])(router.pathname)
  )
}

export const getMarketplaceRoute = (
  studentCCG,
  marketplaceDomain,
  routeKey,
  params,
  queryString
) => {
  const studentDiscountURL = studentCCG ? '/student-discount' : ''
  const route = ROUTES[routeKey]

  if (!route) throw new Error(`Undefined route: '${routeKey}'`)
  return `${marketplaceDomain}${studentDiscountURL}${compile(route)(params)}${queryString || ''}`
}

export const goToRoute = (router, route) => {
  const currentPath = router.asPath
  router.push({
    pathname: route,
    query: {
      returnTo: currentPath
    }
  })
}

export const checkForParam = (param, notFoundValue) => {
  const urlParams = new URLSearchParams(window.location.search)
  return JSON.parse(urlParams.get(param)) || notFoundValue
}

export const getUrl = (name) => {
  const url = ROUTES[name]
  if (!url) throw Error('Could not find URL')
  return url
}

export const createUrl = (name, params) => {
  const url = getUrl(name)
  return compile(url, { encode: encodeURIComponent })(params)
}

export const getUrlWithReturn = (url, returnTo) => {
  const returnToParam = returnTo
    ? `?returnTo=${encodeURIComponent(returnTo)}`
    : ''

  return `${url}${returnToParam}`
}

export const prependModernHost = (url) => {
  return `${process.env.NEXT_PUBLIC_MODERN_HOST}${url}`
}

export const getBrandEditUrl = (id) => {
  return createUrl('cms.brand_list.edit', { id })
}

export const getBrandViewUrl = (id) => {
  return createUrl('cms.brand_list.view', { id })
}

export const getCollectionDiscountsUrl = (id) => {
  return createUrl('cms.collections.discounts', { id })
}

export const getCollectionViewUrl = (id) => {
  return createUrl('cms.collections.view', { id })
}

export const getCollectionEditUrl = (id) => {
  return createUrl('cms.collections.edit.details', { id })
}

export const navigateToCollectionsEdit = (history, id) => {
  const newLocation = createUrl('cms.collections.edit.details', { id })
  history.push(newLocation)
}

export const navigateToCollectionCreate = (history) => {
  const newLocation = createUrl('cms.collections.create')

  history.push(newLocation)
}

export const getCampaignsEditUrl = ({ campaignSlug, countrySlug }) => {
  return createUrl('cms.campaigns.edit.details', {
    campaignSlug,
    countrySlug
  })
}

export const navigateToCampaignsCreate = (history) => {
  const newLocation = createUrl('cms.campaigns.create')

  history.push(newLocation)
}

export const navigateToBrandCreate = (history) => {
  const newLocation = createUrl('cms.brand_list.create')
  history.push(newLocation)
}

export const navigateToBrandItemEdit = (history, id) => {
  const brandEditUrl = createUrl('cms.brand_list.edit', { id })
  history.push(brandEditUrl)
}

export const navigateToBrandList = (history) => {
  const brandListUrl = createUrl('cms.brand_list')
  history.push(brandListUrl)
}

export const navigateToCollectionList = (history) => {
  const collectionListUrl = createUrl('cms.collections')
  history.push(collectionListUrl)
}

export const navigateToDiscountView = (history, id) => {
  const url = createUrl('cms.discounts.view', { id })
  history.push(getUrlWithReturn(url, createUrl('cms.discounts')))
}

export const navigateToDiscountCreate = (history) => {
  const newLocation = createUrl('cms.discounts.create')
  history.push(newLocation)
}

export const navigateToContentItemEdit = (history, id) => {
  const contentEditUrl = createUrl('cms.content.edit', { id })
  history.push(contentEditUrl)
}

export const getProductsViewUrl = (id) => {
  return createUrl('cms.products.view', { id })
}
export const getProductEditUrl = (id) => {
  return createUrl('cms.products.edit', { id })
}

export const navigateToProductEdit = (history, id) => {
  const productEditUrl = createUrl('cms.products.edit', { id })
  history.push(productEditUrl)
}

export const navigateToProductCreate = (history) => {
  const newLocation = createUrl('cms.products.create')

  history.push(newLocation)
}

export const navigateToDiscountEdit = (history, id, countryCode) => {
  const discountEditUrl = createUrl('cms.discounts.edit.details', {
    id,
    countryCode
  })
  history.push(discountEditUrl)
}

export const navigateToEditCodes = (history, id) => {
  const discountsCodesUrl = createUrl('cms.discounts.codes.edit', { id })
  history.push(discountsCodesUrl)
}

export const navigateToPollList = (history) => {
  const newLocation = createUrl('cms.polls')
  history.push(newLocation)
}

export const navigateToPollCreate = (history) => {
  const newLocation = createUrl('cms.polls.create')
  history.push(newLocation)
}

export const getPollEditUrl = (id) => {
  return createUrl('cms.polls.edit', { id })
}

export const navigateToPollEdit = (history, id) => {
  const pollEditUrl = createUrl('cms.polls.edit', { id })
  history.push(pollEditUrl)
}

export const navigateToReviewCreate = (history) => {
  const newLocation = createUrl('cms.reviews.create')
  history.push(newLocation)
}

export const navigateToReviewEdit = (history, id) => {
  const reviewEditUrl = createUrl('cms.reviews.edit', { id })
  history.push(reviewEditUrl)
}

export const navigateToReviewList = (history) => {
  const newLocation = createUrl('cms.reviews')
  history.push(newLocation)
}

export const getReviewEditUrl = (id) => {
  return createUrl('cms.reviews.edit', { id })
}

export const navigateToDiscountVisibility = (history, id, countryCode) => {
  const discountEditVisibilityUrl = createUrl('cms.discounts.edit.visibility', {
    id,
    countryCode
  })
  history.push(discountEditVisibilityUrl)
}

export const navigateToFeaturedList = (history) => {
  const featuredListUrl = createUrl('cms.featured')
  history.push(featuredListUrl)
}

export const navigateToDiscountList = (history) => {
  const DiscountListUrl = createUrl('cms.discounts')
  history.push(DiscountListUrl)
}

export const getDiscountsViewUrl = (id, returnTo) => {
  const url = createUrl('cms.discounts.view', { id })
  return getUrlWithReturn(url, returnTo)
}

export const getDiscountEditUrl = (id, countryCode) => {
  return createUrl('cms.discounts.edit.details', { id, countryCode })
}

export const navigateToFacetsCreateAttribute = (history) => {
  const newLocation = createUrl('cms.facets.create.attribute')
  history.push(newLocation)
}

export const navigateToFacetsCreateValue = (history) => {
  const newLocation = createUrl('cms.facets.create.value')
  history.push(newLocation)
}

export const navigateToCampaignList = (history) => {
  const campaignsListUrl = createUrl('cms.campaigns')
  history.push(campaignsListUrl)
}

export const navigateToCarouselEdit = (history, carouselUid) => {
  const carouselEditUrl = createUrl('cms.carousel.edit', { id: carouselUid })
  history.push(carouselEditUrl)
}

export const navigateToNotificationList = (history) => {
  const notificationListUrl = createUrl('cms.notifications')
  history.push(notificationListUrl)
}

export const navigateToPromoBlocksItemEdit = (history, blockId, itemId) => {
  const promoBlocksItemEditUrl = createUrl(
    'cms.promo_blocks.item.edit.details',
    {
      blockId,
      id: itemId
    }
  )
  history.push(promoBlocksItemEditUrl)
}

export const navigateToFeaturedItemEdit = (history, redemptionTypeId) => {
  const newLocation = createUrl('cms.featured.edit', { id: redemptionTypeId })

  history.push(newLocation)
}

export const navigateToFeaturedItemCreate = (history) => {
  const newLocation = createUrl('cms.featured.add')

  history.push(newLocation)
}

export const navigateToCarouselCreate = (history) => {
  const carouselCreate = createUrl('cms.carousel.create')

  history.push(carouselCreate)
}

export const navigateToDiscountEditRedemption = (history, id, countryCode) => {
  const editRedemption = createUrl('cms.discounts.edit.redemption', {
    id,
    countryCode
  })

  history.push(editRedemption)
}

export const navigateToDiscountAdditionalContentEducation = (
  history,
  id,
  countryCode
) => {
  const discountsCodesUrl = createUrl(
    'cms.discounts.edit.additional_content_education',
    {
      id,
      countryCode
    }
  )
  history.push(discountsCodesUrl)
}

export const navigateToCollectionsOfferList = (history, id) => {
  const collectionsOfferCreate = createUrl('cms.collections.discounts', {
    id
  })

  history.push(collectionsOfferCreate)
}

export const getConnectEditDetailsUrl = (id) => {
  return createUrl('cms.connect.edit.details', { id })
}

export const getConnectViewUrl = (id, returnTo) => {
  const url = createUrl('cms.connect.view', { id })
  return getUrlWithReturn(url, returnTo)
}

export const navigateToConnectEditDetails = (history, id) => {
  const url = createUrl('cms.connect.edit.details', { id })
  history.push(url)
}

export const navigateToConnectListView = (history, id) => {
  const url = createUrl('cms.connect.view', { id })
  history.push(getUrlWithReturn(url, createUrl('cms.connect')))
}

export const navigateToConnectEditCustomise = (history, id) => {
  const url = createUrl('cms.connect.edit.customise', { id })
  history.push(url)
}

export const navigateToConnectList = (history) => {
  const promoBlocksItemListUrl = createUrl('cms.connect')
  history.push(promoBlocksItemListUrl)
}

export const navigateToConnectCreate = (history) => {
  const newLocation = createUrl('cms.connect.create')

  history.push(newLocation)
}

export const getPromoGamesEditUrl = (id) => {
  return createUrl('cms.games.edit.details', { id })
}

export const navigateToPromoGamesCreate = (history) => {
  const promoGameCreateUrl = createUrl('cms.games.create')

  history.push(promoGameCreateUrl)
}

export const navigateToConnectEditCopy = (history, id) => {
  history.push(createUrl('cms.connect.edit.copy', { id }))
}

export const navigateToPromoGamesEditContentUrl = (history, id) => {
  const promoGamesEditContentUrl = createUrl('cms.games.edit.content', { id })
  history.push(promoGamesEditContentUrl)
}

export const navigateToPromoGamesList = (history) => {
  const promoGamesListUrl = createUrl('cms.games')

  history.push(promoGamesListUrl)
}

export const getNotificationsViewUrl = (id, returnTo) => {
  const url = createUrl('cms.notifications.view', { id })
  return getUrlWithReturn(url, returnTo)
}

export const navigateToNotificationsCreate = (history) => {
  const notificationsCreateUrl = createUrl('cms.notifications.create')
  history.push(notificationsCreateUrl)
}

export const getNotificationsEditUrl = (id) => {
  return createUrl('cms.notifications.edit', { id })
}

export const navigateToPromoGamesEditContentQuestion = (
  history,
  id,
  questionId
) => {
  const promoGamesEditContentQuestionUrl = createUrl(
    'cms.games.edit.content.question',
    { id, questionId }
  )

  history.push(promoGamesEditContentQuestionUrl)
}

export const getContentViewUrl = (id) => {
  return createUrl('cms.content.view', { id })
}

export const getContentEditUrl = (id) => {
  return createUrl('cms.content.edit', { id })
}

export const navigateToContentCreate = (history) => {
  const newLocation = createUrl('cms.content.create')

  history.push(newLocation)
}

export const navigateToDiscountsCreate = (history, collectionId) => {
  const url = createUrl('cms.collections.discounts.create', {
    id: collectionId
  })
  history.push(url)
}

export const navigateToPromoBlocksItemCreate = (
  history,
  blockId,
  promoBlockType
) => {
  const url = createUrl('cms.promo_blocks.item.create', {
    blockId,
    promoBlockType
  })
  history.push(url)
}

export const getPromoBlocksItemEditUrl = (blockId, itemId) => {
  return createUrl('cms.promo_blocks.item.edit.details', {
    blockId,
    id: itemId
  })
}

export const navigateToPromoBlocksCreate = (history) => {
  const newLocation = createUrl('cms.promo_blocks.create')

  history.push(newLocation)
}

export const getPromoBlocksItemListUrl = (id) => {
  return createUrl('cms.promo_blocks.item.list', {
    blockId: id
  })
}
