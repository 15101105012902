import Colours from '@thebeansgroup/ui_style_helpers/colours'

import { BannerProps, ColourProps } from './types'

export const ICON_WIDTH = '24px'
export const ICON_HEIGHT = '24px'
export const TYPE_COLOURS = {
  info: {
    contentColour: Colours('colbalt', 500),
    border: Colours('colbalt', 100),
    background: Colours('colbalt', 50)
  },
  success: {
    contentColour: Colours('successGreen', 700),
    border: Colours('successGreen', 300),
    background: Colours('successGreen', 200)
  },
  warning: {
    contentColour: Colours('warningAmber', 700),
    border: Colours('warningAmber', 300),
    background: Colours('warningAmber', 200)
  },
  danger: {
    contentColour: Colours('errorRed', 700),
    border: Colours('errorRed', 300),
    background: Colours('errorRed', 200)
  }
}

export const TAIL_WIND_TYPE_COLOURS: Record<BannerProps['type'], ColourProps> =
  {
    info: {
      contentColour: 'colbalt-500',
      border: 'colbalt-100',
      background: 'colbalt-50'
    },
    success: {
      contentColour: 'success-green-700',
      border: 'success-green-300',
      background: 'success-green-200'
    },
    warning: {
      contentColour: 'warning-amber-700',
      border: 'warning-amber-300',
      background: 'warning-amber-200'
    },
    danger: {
      contentColour: 'error-red-700',
      border: 'error-red-300',
      background: 'error-red-200'
    }
  }
