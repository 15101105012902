import { ReactElement } from 'react'

import { Nullable } from '@root/src/typings/utils'

import { TPagePopupProps } from './types'

const PagePopup = ({
  open,
  content
}: TPagePopupProps): Nullable<ReactElement> => {
  if (!open) return null

  return (
    <div className='h-full bg-grey-200 bg-opacity-50 absolute w-full z-4 flex justify-center'>
      {content}
    </div>
  )
}

export default PagePopup
