import { TOOLTIP_STORAGE_NAME } from '@constants/routes'
import { isExternalRoute } from '@helpers/routes'

export const getTargetProp = (link: string): string => {
  if (isExternalRoute(link)) {
    return '_blank'
  }

  return ''
}

export const shouldRenderToolTip = (link: string): boolean => {
  const storedValue = localStorage.getItem(TOOLTIP_STORAGE_NAME)
  const parsedStorage = storedValue ? JSON.parse(storedValue) : null

  if (!parsedStorage?.includes(link)) return false

  return true
}
