import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

import { USER_WIDTH } from '@constants/user'

import { EMAIL_TEXT_WIDTH } from './constants'

export const userHolder = css`
  position: relative;
`

export const user = css`
  width: auto;
  display: flex;
  align-items: center;
  color: ${Colours('white')};
  padding: 8px 10px;
  background: transparent;
  border: none;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${Colours('white')}15;
  }

  ${desktop} {
    width: ${USER_WIDTH};
  }
`

export const userTextHolder = css`
  display: none;

  ${desktop} {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
  }

  p {
    width: ${EMAIL_TEXT_WIDTH};
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
`

export const chevronIcon = css`
  display: none;

  ${desktop} {
    display: flex;
  }
`

export const separator = css`
  width: 100%;
  height: 1px;
  background-color: ${Colours('grey', 50)};
  margin: 4px 0;
`
