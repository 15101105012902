import AccountIcon from '@thebeansgroup/ui_icons/account_icon'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import * as styles from './styles'

const UserIcon = () => {
  return (
    <span
      css={styles.userIcon}
      data-testid='user-icon'
    >
      <AccountIcon
        colour={Colours('offBlack', 300)}
        width='16px'
        height='16px'
      />
    </span>
  )
}

export default UserIcon
