import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

import Typography from '@thebeansgroup/ui/typography'
import ChevronIconThin from '@thebeansgroup/ui_icons/chevron_icon_thin'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import { useIdToken } from '@root/src/hooks/use_id_token'

import PopoverMenu from '@components/popover_menu'
import PopoverMenuItem from '@components/popover_menu_item'
import UserIcon from '@components/user_icon'
import { logUserOut } from '@helpers/auth'

import { USER_MENU_TOP_ITEMS } from './constants'
import * as styles from './styles'

const User = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const componentRef = useRef(null)

  const { email } = useIdToken()
  const router = useRouter()

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setMenuOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [componentRef])

  return (
    <div
      css={styles.userHolder}
      ref={componentRef}
    >
      <button
        css={styles.user}
        onClick={toggleMenuOpen}
      >
        <UserIcon />
        <span css={styles.userTextHolder}>
          <Typography
            variant='subhead'
            component='p'
            colour='white-300'
            css={styles.userEmail}
          >
            {email}
          </Typography>
        </span>
        <span css={styles.chevronIcon}>
          <ChevronIconThin
            colour={Colours('white')}
            width='12px'
            height='12px'
          />
        </span>
      </button>
      <AnimatePresence mode='wait'>
        <PopoverMenu menuOpen={menuOpen}>
          {USER_MENU_TOP_ITEMS.map((item) => (
            <PopoverMenuItem
              title={item.title}
              key={item.title}
              link={item.link}
            />
          ))}
          <div css={styles.separator} />
          <PopoverMenuItem
            title='Logout'
            buttonAction={() => logUserOut(router)}
          />
        </PopoverMenu>
      </AnimatePresence>
    </div>
  )
}

export default User
