import { gql } from '@apollo/client'

export const LOCAL_STORAGE_KEY = 'selectedOrganisationId'

export const GET_ORGANISATION_QUERY = gql`
  query OrganisationQuery {
    cognitoViewer {
      organisations {
        brandUids
        brandHeadquartersCountry
        brandSize
        territories
        companyName
        consumerGroups
        id
        licenseNetTotal
        isEligibleForAnalytics
        accountId
        pricingTier
        customerSupportOwner
        createdAt
        brands {
          nodes {
            logo
            logoMediaUid
            name
            brandId
            categories {
              nodes {
                name
              }
            }
            country {
              name
              code
            }
          }
        }
      }
    }
  }
`

export const GDPR_RED_COUNTRIES = [
  'China',
  'Russia',
  'India',
  'Hong Kong',
  'Taiwan',
  'Macao'
]

export const GDPR_GREEN_COUNTRIES = [
  'Andorra',
  'Argentina',
  'Austria',
  'Belgium',
  'Bulgaria',
  'Canada',
  'Croatia',
  'Republic of Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Faroe Islands',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Guernsey',
  'Hungary',
  'Ireland',
  'Israel',
  'Isle of Man',
  'Italy',
  'Japan',
  'Jersey',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'New Zealand',
  'Republic of Korea',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'United Kingdom',
  'United States'
]

export const AFFJET_ORG = 'Affjet'
export const NON_AFFJET_ORG = 'Non-Affjet'
export const MANUAL_ORG = 'Manual'
export const CPC_ORG = 'CPC'
export const CODE_TRACK_PRO_ORG = 'Code Track Pro'
export const CPV_ORG = 'CPV (Self-service)'
export const CPA_ORG = 'CPA (Self-service)'

export const ORGANISATIONS_ERROR_MESSAGE =
  'There was an error fetching data for your account, we are looking into this.'

export const ORGANISATIONS_SNOWPLOW_SCHEMA =
  'iglu:com.studentbeans/organisation/jsonschema/1-0-0'

export const SHOW_LEADS_FEE_THRESHOLD = 2500
export const POD_CUSTOMER_SUPPORT = 'Caitlin Uglow'
export const POD_GROUP_TEST_DATE = '2024-07-18T13:00:00.000Z'

export const TESTING_GROUP = [
  'catrin.anthony@cultbeauty.co.uk',
  'danny.catapano@tfs.com',
  'isobel.bridge@tfs.com'
]

export const GROWTH_PRICING_TIER = 'Growth'
export const STARTER_PRICING_TIER = 'Starter'
export const LEGACY_SELF_SERVICE_TIER = 'Self-service'
