import { useContext, useEffect, useState } from 'react'

import Typography from '@thebeansgroup/ui/typography'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import { OrganisationsContext } from '@root/src/contexts/organisations/provider'

import Placeholder from '@components/placeholder'
import { SHOW_LEADS_FEE_THRESHOLD } from '@constants/organisations'
import { isOrganisationLicenseOverFee } from '@helpers/organisations'
import { useLazyQuery } from '@hooks/use_lazy_query'

import { GET_LEADS_COUNT_QUERY } from './constants'
import * as styles from './styles'

const LeadsCount = () => {
  const [preLoading, setPreLoading] = useState(true)
  const { selectedOrganisation } = useContext(OrganisationsContext)

  const [getLeadsCount, { data, loading }] = useLazyQuery(
    GET_LEADS_COUNT_QUERY,
    {
      variables: {
        brandUids: selectedOrganisation?.brandUids
      }
    }
  )

  useEffect(() => {
    if (selectedOrganisation) {
      setPreLoading(false)

      if (
        isOrganisationLicenseOverFee(
          selectedOrganisation,
          SHOW_LEADS_FEE_THRESHOLD
        )
      ) {
        getLeadsCount()
      }
    }
  }, [selectedOrganisation, getLeadsCount])

  const renderCount = () => {
    if (loading || preLoading)
      return (
        <div css={styles.loadingContainer}>
          <Placeholder
            rounded
            width='14px'
            height='14px'
            colour={Colours('neutral', 50)}
          />
        </div>
      )

    return (
      <Typography
        variant='footnote'
        component='span'
        colour='neutral-350'
      >
        {data?.cognitoViewer?.leads?.length || 0}
      </Typography>
    )
  }

  return (
    <div
      css={styles.count}
      data-testid='leads-count'
    >
      {renderCount()}
    </div>
  )
}

export default LeadsCount
