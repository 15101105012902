import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

import { ORGANISATION_SELECTOR_HEIGHT } from '@constants/layout'

export const organisationSelector = css`
  display: flex;
  padding: 9px 12px;
  align-items: center;
  gap: 12px;
  height: ${ORGANISATION_SELECTOR_HEIGHT};
  border-radius: 8px;
  border: 1px solid ${Colours('neutral', 100)};
  width: 100%;
  background-color: ${Colours('white')};
  margin-top: 20px;

  ${desktop} {
    margin-top: 0;
  }

  > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const organisationIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-width: 30px;
  height: 30px;

  > p {
    line-height: 1;
  }
`

export const iconBackground = css`
  background-color: ${Colours('offBlack', 300)};
`

export const dropdownIcon = css`
  display: flex;
  margin-left: auto;
`

export const menuItem = css`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;

  > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`
