import { captureException } from '@sentry/nextjs'

import { TOKEN_EXPIRED_ERROR } from '@constants/auth'
import { AFFJET_ORG } from '@constants/organisations'
import { trackUnstructEvent } from '@tracker/snowplow'

import {
  DASHBOARD_CLASSNAME,
  DASHBOARD_IDS_BY_TYPE,
  QUICKSIGHT_CLICK_SCHEMA,
  SHOULD_DASHBOARD_TYPE_SHOW
} from './constants'

export const getDashboardUrl = (data) =>
  data?.cognitoViewer?.quicksightDashboard?.url
export const hasTokenExpired = (errors) =>
  errors?.[0]?.message === TOKEN_EXPIRED_ERROR

export const handleMessage = async (event, email) => {
  if (event.eventLevel === 'ERROR') {
    return captureException(
      `There was an analytics error for ${email} - "${event.eventName}"`
    )
  }
  switch (event.eventName) {
    case 'SELECTED_SHEET_CHANGED': {
      handleQuickSightAction('tab_click')
      break
    }
    case 'MODAL_OPENED': {
      handleQuickSightAction('tile_export', event?.message?.modalName)
      break
    }
    case 'ERROR_OCCURRED': {
      captureException(
        `${email} dashboard error: Error Code: ${event.message.errorCode}`
      )
      break
    }
  }
}

export const handleQuickSightAction = (eventName, eventAction) => {
  trackUnstructEvent(QUICKSIGHT_CLICK_SCHEMA, {
    click_event_type: eventName,
    click_event_action: eventAction
  })
}

export const getDashboardId = (selectedOrganisation) => {
  if (!selectedOrganisation.brandUids.length) return false

  const analyticsType = selectedOrganisation?.isEligibleForAnalytics
  const dashboardId = DASHBOARD_IDS_BY_TYPE?.[analyticsType]

  return dashboardId || DASHBOARD_IDS_BY_TYPE[AFFJET_ORG]
}

export const clearDashboard = () => {
  const embeddedIframes = document.querySelectorAll('iframe')
  embeddedIframes.forEach((iframe) => {
    if (iframe.classList.contains(DASHBOARD_CLASSNAME)) {
      iframe.remove()
    }
  })
}

export const shouldRenderDashboard = (selectedOrganisation) => {
  if (process.env.NEXT_PUBLIC_APP_ENV === 'staging') return true

  return (
    SHOULD_DASHBOARD_TYPE_SHOW?.[
      selectedOrganisation?.isEligibleForAnalytics
    ] ?? true
  )
}

export const logError = (error, email) => {
  const formatedError =
    typeof error !== 'string' ? JSON.stringify(error) : error
  captureException(`Dashboard Error for ${email}: ${formatedError}`)
}
