import { createContext, useState } from 'react'

import {
  ExpandedViewContextType,
  ExpandedViewProviderProps
} from '@root/src/typings/contexts/expanded_view'
import { NullableReactElement } from '@root/src/typings/utils'

export const ExpandedViewContext = createContext<ExpandedViewContextType>({
  currentView: null,
  setCurrentView: /* istanbul ignore next */ () => {}
})

export const ExpandedViewProvider = ({
  children
}: ExpandedViewProviderProps): React.ReactElement => {
  const [currentView, setCurrentView] = useState<NullableReactElement>(null)

  return (
    <ExpandedViewContext.Provider value={{ currentView, setCurrentView }}>
      {children}
    </ExpandedViewContext.Provider>
  )
}
