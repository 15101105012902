export const TOAST_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success'
}

export const COPIED_SUCESSFULLY = 'Copied successfully'
export const FAILED_TO_COPY = 'Failed to copy'

export const CHANGES_SAVED = 'Changes saved'
export const BRAND_SAVED = 'Brand saved'
