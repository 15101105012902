import { useContext } from 'react'

import Typography from '@thebeansgroup/ui/typography'

import { ExpandedViewContext } from '@root/src/contexts/expanded_view/provider'
import { OrganisationsContext } from '@root/src/contexts/organisations/provider'
import { useIdToken } from '@root/src/hooks/use_id_token'

import ExpandedView from '@components/expanded_view'
import HeaderButton from '@components/header_button'
import PagePopup from '@components/page_popup'
import { TOrganisationContext } from '@typings/contexts/contexts'
import { Organisation } from '@typings/graphql/graphql'
import { Nullable, NullableReactElement } from '@typings/utils'

import { showHeaderButton } from './helpers'
import * as styles from './styles'
import { PageLayoutProps } from './types'

const PageLayout = ({
  title = '',
  pageDescription = '',
  children,
  cardStyling = true,
  headerWidgets = [],
  popupContent = null
}: PageLayoutProps): NullableReactElement => {
  const { currentView } = useContext(ExpandedViewContext)

  const { email } = useIdToken()
  const { selectedOrganisation } =
    useContext<TOrganisationContext>(OrganisationsContext)

  const renderHeaderWidgets = (): Nullable<React.ReactElement[]> => {
    if (!showHeaderButton(email, selectedOrganisation as Organisation))
      return null

    if (!headerWidgets?.length) return null

    return headerWidgets.map((widget, index) => (
      <HeaderButton
        key={index}
        route={widget.route}
        title={widget.title}
        icon={widget.icon}
        action={widget.action}
        target={widget.target}
      />
    ))
  }

  const renderPagePopup = (): NullableReactElement => {
    if (!popupContent?.content) return null

    return (
      <PagePopup
        content={popupContent.content}
        open={popupContent.showContent}
      />
    )
  }

  const renderPageDescription = (): NullableReactElement => {
    if (!pageDescription) return null

    return (
      <Typography
        variant='body'
        colour='neutral-350'
      >
        {pageDescription}
      </Typography>
    )
  }

  const renderTitle = (): NullableReactElement => {
    if (!title) return null

    return (
      <div className='flex flex-col gap-3'>
        <Typography
          variant='title1'
          component='h1'
          colour='off-black-300'
        >
          {title}
        </Typography>
        {renderPageDescription()}
      </div>
    )
  }

  return (
    <div
      css={styles.pageLayout}
      data-testid='page-layout'
    >
      {renderPagePopup()}
      <div
        data-testid='page-content'
        css={[
          styles.pageContentDefaultStyles,
          currentView && styles.smallPageContent
        ]}
      >
        <div css={styles.headerContainer}>
          {renderTitle()}
          {renderHeaderWidgets()}
        </div>
        <div
          css={[
            cardStyling && styles.card,
            !cardStyling && styles.minimalStyling
          ]}
        >
          {children}
        </div>
      </div>
      <ExpandedView />
    </div>
  )
}

export default PageLayout
