import { NO_INDEX } from '@constants/metadata'

export const getRobotsContent = (robots = []) => {
  if (shouldAddNoIndex(robots)) robots.push(NO_INDEX)
  return robots.join(', ')
}

export const shouldAddNoIndex = (robots) => {
  if (robots.includes(NO_INDEX)) return false

  return process.env.NEXT_PUBLIC_APP_ENV === 'staging'
}
