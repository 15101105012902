import { usePathname } from 'next/navigation'
import Router from 'next/router'
import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'

import { getNextRoute } from '@helpers/routes'

import {
  isRouteLogin,
  isUserAuthenticated,
  sendUserToLoginScreen
} from './helpers'

const Auth = ({ children }) => {
  const [isAuthorised, setIsAuthorised] = useState(false)
  const loginRoute = getNextRoute('login')
  const discountRoute = getNextRoute('discount')
  const pathname = usePathname()

  useEffect(() => {
    if (isUserAuthenticated()) {
      setIsAuthorised(true)
    } else {
      setIsAuthorised(false)

      if (!isRouteLogin(pathname, loginRoute)) {
        sendUserToLoginScreen(Router.push, discountRoute)
      }
    }
  }, [pathname, loginRoute, discountRoute])

  const renderChildren = () => {
    if (!pathname) return null

    if (!isAuthorised && isRouteLogin(pathname, loginRoute)) return children

    if (isAuthorised) return children
  }

  return <Fragment>{renderChildren()}</Fragment>
}

Auth.propTypes = {
  children: PropTypes.node.isRequired
}

export default Auth
