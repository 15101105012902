export const BADGE_TYPE = {
  GREEN: 'green',
  GREY_LIGHT: 'greyLight',
  GREY_DARK: 'greyDark',
  PURPLE: 'purple',
  STARTER: 'Starter',
  GROWTH: 'Growth',
  ENTERPRISE: 'Managed'
}

export const STYLES: Record<string, string> = {
  [BADGE_TYPE.GREEN]: 'bg-margarita-100',
  [BADGE_TYPE.GREY_LIGHT]: 'bg-grey-25',
  [BADGE_TYPE.GREY_DARK]: 'bg-grey-25',
  [BADGE_TYPE.PURPLE]: 'bg-wild-black-berry-100',
  [BADGE_TYPE.STARTER]: 'bg-success-green-300',
  [BADGE_TYPE.GROWTH]: 'bg-pion-cyan-100',
  [BADGE_TYPE.ENTERPRISE]: 'bg-colbalt-100'
}

export const TEXT_COLOURS: Record<string, string> = {
  [BADGE_TYPE.GREEN]: 'margarita-500',
  [BADGE_TYPE.GREY_LIGHT]: 'grey-350',
  [BADGE_TYPE.GREY_DARK]: 'grey-400',
  [BADGE_TYPE.PURPLE]: 'wild-black-berry-500',
  [BADGE_TYPE.STARTER]: 'off-black-300',
  [BADGE_TYPE.GROWTH]: 'off-black-300',
  [BADGE_TYPE.ENTERPRISE]: 'colbalt-400'
}
