import { css, SerializedStyles } from '@emotion/react'

import { PlaceholderProps } from './types'

export const placeholder = (
  width: PlaceholderProps['width'],
  height: PlaceholderProps['height'],
  circular: PlaceholderProps['circular'],
  colour: PlaceholderProps['colour']
): SerializedStyles => css`
  position: relative;
  height: ${height};
  width: ${width};
  background: ${colour};
  ${circular && 'border-radius: 50%;'};
  overflow: hidden;
`

export const rounded = css`
  border-radius: 5px;
`
