import { css, SerializedStyles } from '@emotion/react'

export const banner = (
  borderColour: string,
  backgroundColour: string
): SerializedStyles => css`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid ${borderColour};
  background-color: ${backgroundColour};
`

export const text = css`
  padding: 0 16px;
`

export const iconContainer = css`
  width: 24px;
  height: 24px;
`
