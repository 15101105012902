const features = {
  create_brand: true,
  integrations: true,
  edit_integrations: true,
  integration_create_type: false,
  leads_upsell: true,
  settings: true,
  image_improvements: true,
  winsday_upsell: true,
  upgrades_page: true,
  'discount_section:edit_page:premium_brand_labels': true,
  editorial_promo_blocks: true,
  campaign_markdown_content: true,
  campaign_evergreen: true,
  facets: true,
  promoGame: true,
  cms_routes: false,
  hosted_integration_type: true,
  onboarding: true,
  resources_page: true,
  category_benchmarking: true
}

export default features
