import Colours from '@thebeansgroup/ui_style_helpers/colours'

import { TOAST_TYPES } from '@constants/toast'

export const TOAST_TYPES_COLOURS = {
  [TOAST_TYPES.INFO]: {
    background: Colours('offBlack', 300),
    text: Colours('white')
  },
  [TOAST_TYPES.ERROR]: {
    background: Colours('errorRed', 500),
    text: Colours('white')
  },
  [TOAST_TYPES.WARNING]: {
    background: Colours('warningAmber', 500),
    text: Colours('white')
  },
  [TOAST_TYPES.SUCCESS]: {
    background: Colours('successGreen', 500),
    text: Colours('white')
  }
}

export const ANIMATION_TIME = 1000
export const CLOSE_TIME = 5000
