import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'

import { nullEmptyObjects } from './helpers'

export const CreateDataContext = createContext({})

export const CreateDataProvider = ({ children, initialState }) => {
  const [createData, setCreateData] = useState(initialState)
  const [createDataErrors, setCreateDataErrors] = useState()
  const [localStorageKey, setLocalStorageKey] = useState('')

  useEffect(() => {
    if (createData && localStorageKey) {
      localStorage.setItem(localStorageKey, JSON.stringify(createData))
    }
  }, [createData, localStorageKey])

  const buildDefaultCreateState = (pageConfig, pageLocalStorageKey, data) => {
    setLocalStorageKey(pageLocalStorageKey)
    let createDataInitialState = JSON.parse(
      localStorage.getItem(pageLocalStorageKey)
    )

    if (!createDataInitialState) {
      createDataInitialState = {}
      pageConfig?.forEach((page) => {
        page.stateKeys?.forEach((stateKey) => {
          createDataInitialState[stateKey.key] =
            nullEmptyObjects(data[stateKey.key]) ||
            stateKey.initialValue ||
            null
        })
      })
    }

    setCreateData(createDataInitialState)
  }

  const resetCreateData = () => {
    setCreateData(null)
    setCreateDataErrors(null)
  }

  const addCreateDataErrors = (errors) => {
    setCreateDataErrors(errors)
  }

  const updateStateKey = (keysValues) => {
    setCreateData({
      ...createData,
      ...keysValues
    })

    const updatedKeys = Object.keys(keysValues)

    if (createDataErrors) {
      const updatedErrors = createDataErrors.filter(
        (errorKey) => !updatedKeys.includes(errorKey)
      )
      setCreateDataErrors(updatedErrors)
    }
  }

  return (
    <CreateDataContext.Provider
      value={{
        createData,
        createDataErrors,
        buildDefaultCreateState,
        updateStateKey,
        resetCreateData,
        addCreateDataErrors
      }}
    >
      {children}
    </CreateDataContext.Provider>
  )
}

CreateDataProvider.defaultProps = {
  initialState: null
}

CreateDataProvider.propTypes = {
  children: PropTypes.any.isRequired,
  initialState: PropTypes.object
}
