export const getOrganisationsContext = (organisation) => {
  return {
    organisation_id: organisation.id,
    organisation_name: organisation.companyName,
    brand_uids: organisation.brands?.nodes?.map((brand) => brand?.brandId) ?? [
      ''
    ],
    brand_names: organisation.brands?.nodes?.map((brand) => brand?.name) ?? ['']
  }
}

export const organisationHasCorrectBrandData = (organisationData) => {
  return !!organisationData.brand_uids.filter(Boolean).length
}
