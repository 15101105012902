import PropTypes from 'prop-types'
import React, { Fragment, useContext, useEffect, useState } from 'react'

import { OrganisationsContext } from '@root/src/contexts/organisations/provider'
import { useIdToken } from '@root/src/hooks/use_id_token'

import MobileNavBackground from '@components/mobile_nav_background'
import NavItem from '@components/nav_item'
import OrganisationSelector from '@components/organisation_selector'
import SafeImage from '@components/safe_image'
import {
  NOTIFICATION_STORAGE_NAME,
  TOOLTIP_STORAGE_NAME
} from '@constants/routes'
import { withAssetPrefix } from '@helpers/assets'

import { BOTTOM_NAV_ITEMS } from './constants'
import {
  getNavLinks,
  shouldShowLinkWithToggle,
  shouldShowNotification
} from './helpers'
import * as styles from './styles'

const Navbar = ({ mobileNavOpen, toggle }) => {
  const { organisationsError, selectedOrganisation } =
    useContext(OrganisationsContext)
  const [notifications, setNotifications] = useState([])
  const [tooltips, setTooltips] = useState([])
  const { email } = useIdToken()
  const storedNotifications = localStorage.getItem(NOTIFICATION_STORAGE_NAME)
  const storedTooltips = localStorage.getItem(TOOLTIP_STORAGE_NAME)
  useEffect(() => {
    const parsedNotifications = JSON.parse(storedNotifications)
    const parsedTooltips = JSON.parse(storedTooltips)

    if (
      storedNotifications &&
      parsedNotifications.length !== notifications.length
    ) {
      setNotifications(parsedNotifications)
    }

    if (storedTooltips && parsedTooltips.length !== tooltips.length) {
      setTooltips(parsedTooltips)
    }
  }, [
    storedTooltips,
    tooltips.length,
    storedNotifications,
    notifications.length
  ])

  const removeTooltip = (event, href) => {
    event.preventDefault()
    setTooltips(tooltips.filter((tooltip) => tooltip !== href))
  }

  const renderLinks = (links) => {
    return links.map(
      ({ title, showToggle, icon, href, badge, restrictedEmails }, index) => {
        if (!shouldShowLinkWithToggle(showToggle)) return null

        if (restrictedEmails.includes(email)) return null

        return (
          <NavItem
            key={index}
            title={title}
            icon={icon}
            link={href}
            badge={badge}
            notification={shouldShowNotification(notifications, href)}
            removeTooltip={removeTooltip}
          />
        )
      }
    )
  }

  const renderLogo = () => {
    return (
      <SafeImage
        height={51}
        width={178}
        alt=''
        src={withAssetPrefix('images/pion-branded-logo.svg')}
      />
    )
  }

  const renderOrganisationSelector = () => {
    if (organisationsError) return null

    return (
      <div css={styles.organisationsContainer}>
        <OrganisationSelector />
      </div>
    )
  }

  const renderTopNavLinks = () => {
    if (organisationsError) return null

    return <div>{renderLinks(getNavLinks(selectedOrganisation, email))}</div>
  }

  return (
    <Fragment>
      <nav
        css={[styles.nav, mobileNavOpen && styles.activeNav]}
        data-testid='nav'
      >
        <div css={styles.navLogoContainer}>{renderLogo()}</div>
        {renderOrganisationSelector()}
        <div css={styles.navLinkContainer}>
          {renderTopNavLinks()}
          <div>{renderLinks(BOTTOM_NAV_ITEMS)}</div>
        </div>
      </nav>
      <MobileNavBackground
        mobileNavOpen={mobileNavOpen}
        toggle={toggle}
      />
    </Fragment>
  )
}

Navbar.propTypes = {
  mobileNavOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
}

export default Navbar
