import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

import { ExpandedViewProvider } from '@root/src/contexts/expanded_view/provider'
import { OrganisationsContext } from '@root/src/contexts/organisations/provider'

import Banner from '@components/banner'
import ErrorBoundary from '@components/error_boundary'
import EventBanner from '@components/event_banner'
import Header from '@components/header'
import Navbar from '@components/navbar'
import Toast from '@components/toast'
import { ORGANISATIONS_ERROR_MESSAGE } from '@constants/organisations'

import { CONFIG } from './constants'
import * as styles from './styles'

const PageView = dynamic(() => import('@components/page_view'), { ssr: false })

const Page = ({ backgroundColour, children, pageLabel }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const { organisationsError } = useContext(OrganisationsContext)

  const toggleNavBarMobile = () => {
    setMobileNavOpen(!mobileNavOpen)
  }

  const renderErrorPage = () => {
    if (!organisationsError) return null

    return (
      <div css={styles.wrapper}>
        <main css={styles.main(backgroundColour)}>
          <Header toggle={toggleNavBarMobile} />
          <div css={styles.content}>
            <Navbar
              toggle={toggleNavBarMobile}
              mobileNavOpen={mobileNavOpen}
            />
            <div css={styles.errorPage}>
              <Banner
                text={ORGANISATIONS_ERROR_MESSAGE}
                type='danger'
              />
            </div>
          </div>
        </main>
      </div>
    )
  }

  const renderPage = () => {
    if (organisationsError) return null

    return (
      <ExpandedViewProvider>
        <div css={styles.wrapper}>
          <main css={styles.main(backgroundColour)}>
            <EventBanner config={CONFIG} />
            <Header toggle={toggleNavBarMobile} />
            <div css={styles.content}>
              <Navbar
                toggle={toggleNavBarMobile}
                mobileNavOpen={mobileNavOpen}
              />
              <ErrorBoundary>{children}</ErrorBoundary>
            </div>
            <Toast />
          </main>
        </div>
        <PageView pageLabel={pageLabel} />
      </ExpandedViewProvider>
    )
  }

  return (
    <Fragment>
      {renderPage()}
      {renderErrorPage()}
    </Fragment>
  )
}

Page.propTypes = {
  backgroundColour: PropTypes.string,
  children: PropTypes.any.isRequired,
  pageLabel: PropTypes.string
}

Page.defaultProps = {
  backgroundColour: Colours('white'),
  pageLabel: ''
}

export default Page
