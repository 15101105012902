import { css } from '@emotion/react'

import { TOAST_TYPES_COLOURS } from './constants'

export const toast = (type) => css`
  position: absolute;
  display: none;
  bottom: 40px;
  align-items: center;
  padding: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999;
  background-color: ${TOAST_TYPES_COLOURS[type]?.background};
  gap: 8px;
  border-radius: 10px;
  transform: translate(-50%, 200%);
`

export const active = css`
  display: flex;
  animation: slideIn 0.3s ease-in;
  transform: translate(-50%, 0);

  @keyframes slideIn {
    0% {
      transform: translate(-50%, 200%);
    }

    60% {
      transform: translate(-50%, -20%);
    }

    100% {
      transform: translate(-50%, 0);
    }
  }
`

export const closing = css`
  display: flex;
  animation: slideOut 0.3s ease-in;
  transform: translate(-50%, 200%);

  @keyframes slideOut {
    0% {
      transform: translate(-50%, 0);
    }

    40% {
      transform: translate(-50%, -20%);
    }

    100% {
      transform: translate(-50%, 200%);
    }
  }
`

export const close = css`
  width: 12px;
  height: 12px;
`
