import { Column, Row } from '@thebeansgroup/ui_grids'

import Placeholder from '@components/placeholder'
import * as styles from '@components/quicksight_embed/styles'

const QuicksightPlaceholder = () => (
  <div>
    <div css={styles.placeholderRowSpacing}>
      <Row>
        <Column>
          <Placeholder height='400px' />
        </Column>
      </Row>
    </div>
    <div css={styles.placeholderRowSpacing}>
      <Row>
        <Column mother={6}>
          <Placeholder height='200px' />
        </Column>

        <Column mother={6}>
          <Placeholder height='200px' />
        </Column>
      </Row>
    </div>
    <div css={styles.placeholderRowSpacing}>
      <Row>
        <Column>
          <Placeholder height='400px' />
        </Column>
      </Row>
    </div>
  </div>
)

export default QuicksightPlaceholder
