import { css } from '@emotion/react'

export const wrapper = css`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const main = (backgroundColour) => css`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${backgroundColour};
  overflow: hidden;
  position: relative;
`

export const content = css`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`

export const errorPage = css`
  padding: 40px;
  width: 100%;
`
