import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const blankHeader = css`
  background-color: ${Colours('white')};
  height: 64px;
  border-top: 1px solid ${Colours('grey', 100)};
  border-left: 1px solid ${Colours('grey', 100)};
  border-right: 1px solid ${Colours('grey', 100)};
  border-radius: 10px 10px 0px 0px;
`

export const blankTable = css`
  display: flex;
  border: 1px solid;
  border-image: linear-gradient(${Colours('grey', 100)}, ${Colours('grey', 25)})
    1;
  border-top: 0px;
  background: linear-gradient(
    to bottom,
    ${Colours('white')} 0%,
    transparent 100%
  );
`

export const blankMessage = css`
  width: 472px;
  align-self: center;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`

export const tableButton = css`
  width: fit-content;
`

export const tooltipContainer = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 4px;
  padding: 20px;

  h1 {
    color: white;
  }
`
