import * as styles from './styles'
import { PlaceholderAnimationProps } from './types'

const PlaceholderAnimation = ({
  left = '-60%',
  width = '60%'
}: PlaceholderAnimationProps): React.ReactElement => {
  return (
    <div
      role='presentation'
      aria-hidden='true'
      css={styles.animationOverlay(left, width)}
    />
  )
}

export default PlaceholderAnimation
