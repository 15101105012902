import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

import { NAVBAR_WIDTH } from '@constants/layout'

export const nav = css`
  position: absolute;
  width: ${NAVBAR_WIDTH};
  top: 0px;
  height: 100vh;
  transition: left 0.2s ease-in;
  left: -${NAVBAR_WIDTH};
  background-color: ${Colours('neutral', 10)};
  z-index: 5;
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${desktop} {
    padding: 20px 0;
    position: relative;
    border-style: solid;
    border-width: 0 1px 0 0;
    border-color: ${Colours('neutral', 100)};
    left: 0%;
    flex-grow: 1;
  }
`
export const activeNav = css`
  left: 0%;
`

export const navLinkContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  flex-grow: 1;
`
export const navLogoContainer = css`
  display: flex;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid ${Colours('neutral', 100)};
  background-color: ${Colours('offBlack', 300)};

  ${desktop} {
    display: none;
  }
`

export const organisationsContainer = css`
  padding: 0 20px;
`
