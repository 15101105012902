import { css } from '@emotion/react'

import { EXPANDED_VIEW_PAGE_PADDING, EXPANDED_VIEW_WIDTH } from './constants'

export const animationStyles = {
  height: '100%',
  right: 0,
  position: 'absolute',
  width: `calc(${EXPANDED_VIEW_WIDTH}px + ${EXPANDED_VIEW_PAGE_PADDING})`
}

export const expandedView = css`
  overflow: auto;
  height: 100%;
  padding: 16px 24px 16px 0;
`
