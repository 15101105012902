import { usePathname } from 'next/navigation'
import PropTypes from 'prop-types'

import { OrganisationsProvider } from '@root/src/contexts/organisations/provider'

import { getNextRoute } from '@helpers/routes'

const OrganisationsProviderRenderer = ({ children }) => {
  const pathname = usePathname()
  const loginRoute = getNextRoute('login')

  const renderProvider = () => {
    if (pathname.includes(loginRoute)) return children

    return <OrganisationsProvider>{children}</OrganisationsProvider>
  }

  return renderProvider()
}

OrganisationsProviderRenderer.propTypes = {
  children: PropTypes.node.isRequired
}

export default OrganisationsProviderRenderer
