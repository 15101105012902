import {
  NOTIFICATION_STORAGE_NAME,
  TOOLTIP_STORAGE_NAME
} from '@constants/routes'

const addItem = (storage, route) => {
  const parsedLocalStorage = JSON.parse(localStorage.getItem(storage))
  const itemArray = parsedLocalStorage || []

  if (!itemArray.find((item) => item === route)) {
    itemArray.push(route)

    localStorage.setItem(storage, JSON.stringify(itemArray))
  }
}

const removeItem = (storage, route) => {
  const parsedLocalStorage = JSON.parse(localStorage.getItem(storage))

  if (parsedLocalStorage) {
    const filteredStorage = parsedLocalStorage.filter((item) => item !== route)
    localStorage.setItem(storage, JSON.stringify(filteredStorage))
  }
}

export const setMenuNotificationItem = (route) => {
  addItem(NOTIFICATION_STORAGE_NAME, route)
}

export const removeMenuNotificationItem = (route) => {
  removeItem(NOTIFICATION_STORAGE_NAME, route)
}

export const setTooltipItem = (route) => {
  addItem(TOOLTIP_STORAGE_NAME, route)
}

export const removeTooltipItem = (route) => {
  removeItem(TOOLTIP_STORAGE_NAME, route)
}
