import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const mobileBackground = css`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0;
  opacity: 0;
  pointer-events: none;
  background-color: ${Colours('offBlack', 300)};
  transition: opacity 0.2s linear;
  z-index: 4;
`

export const mobileBackgroundActive = css`
  opacity: 95%;
  pointer-events: auto;
`
