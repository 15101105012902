const CMS_ROUTES = {
  'cms.carousel': '/cms/carousel/list',
  'cms.carousel.view': '/cms/carousel/view/:id',
  'cms.carousel.create': '/cms/carousel/create',
  'cms.carousel.edit': '/cms/carousel/edit/:id/details',
  'cms.categories': '/cms/categories/list',
  'cms.categories.view': '/cms/categories/list/view/:id',
  'cms.categories.edit': '/cms/categories/edit/:id',
  'cms.connect': '/cms/connect/list',
  'cms.connect.create': '/cms/connect/list/create',
  'cms.connect.view': '/cms/connect/list/view/:id',
  'cms.connect.edit.details': '/cms/connect/edit/:id/details',
  'cms.connect.edit.copy': '/cms/connect/edit/:id/copy',
  'cms.connect.edit.customise': '/cms/connect/edit/:id/customise',
  'cms.campaigns': '/cms/campaigns/list',
  'cms.campaigns.create': '/cms/campaigns/create',
  'cms.campaigns.edit.details':
    '/cms/campaigns/edit/:countrySlug/:campaignSlug/details',
  'cms.campaigns.edit.content':
    '/cms/campaigns/edit/:countrySlug/:campaignSlug/content',
  'cms.discounts': '/cms/discounts/list',
  'cms.discounts.view': '/cms/discounts/list/view/:id',
  'cms.discounts.edit.details': '/cms/discounts/edit/:countryCode/:id/details',
  'cms.discounts.edit.additional_content_education':
    '/cms/discounts/edit/:countryCode/:id/additional-content-education',
  'cms.discounts.edit.redemption':
    '/cms/discounts/edit/:countryCode/:id/redemption',
  'cms.discounts.edit.visibility':
    '/cms/discounts/edit/:countryCode/:id/visibility',
  'cms.discounts.codes.edit': '/cms/discounts/:id/codes/edit',
  'cms.discounts.create': '/cms/discounts/list/create',
  'cms.discounts.edit.facets': '/cms/discounts/edit/:countryCode/:id/facets',
  'cms.featured': '/cms/discounts/featured/list',
  'cms.featured.add': '/cms/discounts/featured/add',
  'cms.featured.edit': '/cms/discounts/featured/edit/:id',
  'cms.test': '/cms/test',
  'cms.testWithParams': '/cms/test/:id',
  'cms.notifications': '/cms/notifications/list',
  'cms.notifications.create': '/cms/notifications/create',
  'cms.notifications.view': '/cms/notifications/view/:id',
  'cms.notifications.edit': '/cms/notifications/edit/:id/details',
  'cms.promo_blocks': '/cms/media/list',
  'cms.promo_blocks.create': '/cms/media/list/create',
  'cms.promo_blocks.item.list': '/cms/media/:blockId/item/list',
  'cms.promo_blocks.item.create':
    '/cms/media/:blockId/:promoBlockType/item/create',
  'cms.promo_blocks.item.edit.details':
    '/cms/media/:blockId/item/edit/:id/details',
  'cms.promo_blocks.item.edit.customise':
    '/cms/media/:blockId/item/edit/:id/customise',
  'cms.brand_list': '/cms/brands/list',
  'cms.brand_list.view': '/cms/brands/view/:id',
  'cms.brand_list.edit': '/cms/brands/edit/:id/details',
  'cms.brand_list.edit.premium': '/cms/brands/edit/:id/premium',
  'cms.brand_list.edit.facets': '/cms/brands/edit/:id/facets',
  'cms.brand_list.edit.venues': '/cms/brands/edit/:id/venues',
  'cms.brand_list.create': '/cms/brands/create',
  'cms.collections': '/cms/collections/list',
  'cms.collections.edit.images': '/cms/collections/edit/:id/images',
  'cms.collections.edit.details': '/cms/collections/edit/:id/details',
  'cms.collections.view': '/cms/collections/view/:id',
  'cms.collections.discounts': '/cms/collections/:id/discounts/list',
  'cms.collections.discounts.create':
    '/cms/collections/:id/discounts/list/create',
  'cms.collections.create': '/cms/collections/list/create',
  'cms.products': '/cms/products/list',
  'cms.products.edit': '/cms/products/edit/:id',
  'cms.products.view': '/cms/products/view/:id',
  'cms.products.create': '/cms/products/create',
  'cms.products.rail': '/cms/products/rail',
  'cms.content': '/cms/content/list',
  'cms.content.edit': '/cms/content/edit/:id',
  'cms.content.view': '/cms/content/view/:id',
  'cms.content.create': '/cms/content/list/create',
  'cms.accounts.profiles': '/cms/accounts/profiles',
  'cms.polls': '/cms/polls/list',
  'cms.polls.create': '/cms/polls/create',
  'cms.polls.edit': '/cms/polls/edit/:id/details',
  'cms.reviews': '/cms/reviews/list',
  'cms.reviews.create': '/cms/reviews/create',
  'cms.reviews.edit': '/cms/reviews/edit/:id/details',
  'cms.facets': '/cms/facets/list',
  'cms.facets.create.attribute': '/cms/facets/create/attribute',
  'cms.facets.create.value': '/cms/facets/create/value',
  'cms.games': '/cms/games/list',
  'cms.games.create': '/cms/games/create',
  'cms.games.edit.details': '/cms/games/edit/:id/details',
  'cms.games.edit.content': '/cms/games/edit/:id/content',
  'cms.games.edit.content.question':
    '/cms/games/edit/:id/content/question/:questionId'
}

const PORTAL_ROUTES = {
  home: '/',
  login: '/login',
  loginTerms: '/login/terms',
  changePassword: '/login/change-password',
  resetPassword: '/login/reset-password',
  resetPasswordVerify: '/login/reset-password/verify',
  marketplace: '/marketplace',
  settings: '/settings/general',
  terms: '/terms',
  discount: '/discounts',
  leads: '/leads',
  upgrades: '/upgrades',
  beansIdHelpCentre: 'https://support.wearepion.com/hc/en-gb',
  'discounts.create.ccg': '/discounts/new/create/ccg',
  'discounts.create.redemptionClass': '/discounts/new/create/redemption_class',
  'discounts.create.details': '/discounts/:id/create/details',
  'discounts.create.codes': '/discounts/:id/create/codes',
  'discounts.create.categories': '/discounts/:id/create/categories',
  'marketplace.brand.offer': '/:country/:brand',
  'connects.create': '/connects/create',
  'discounts.edit': '/discounts/:id/edit',
  integrations: '/integrations',
  setup: '/setup',
  blog: '/resources/blog',
  blogPost: '/resources/blog/:slug'
}

export const ROUTES = {
  ...PORTAL_ROUTES,
  ...CMS_ROUTES
}

export const NOTIFICATION_STORAGE_NAME = 'navItemNotifications'
export const TOOLTIP_STORAGE_NAME = 'tooltips'
