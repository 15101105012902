import { sendGAEvent } from '@next/third-parties/google'
import cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { NextRouter } from 'next/router'

import { Nullable } from '@root/src/typings/utils'

import {
  ACCESS_TOKEN_NAME,
  ID_TOKEN_NAME,
  LEADS_RESTRICTED_USER_EMAILS,
  PRODUCTION_TESTING_ACCOUNT
} from '@constants/auth'
import { LoginPayload, Maybe } from '@typings/graphql/graphql'
import { IdToken } from '@typings/token'

import { getNextRoute } from './routes'

export const getToken = (tokenName: string): Nullable<string> =>
  localStorage.getItem(tokenName)

export const storeToken = (token: string, tokenName: string): void => {
  localStorage.setItem(tokenName, token)
  cookies.set(tokenName, token, { path: '/' })
}

export const logUserIntoPortal = (
  tokens: { token: string; name: string }[],
  router: NextRouter
): void => {
  tokens.forEach((token) => {
    storeToken(token.token, token.name)
  })

  const userEmail = jwtDecode<IdToken>(tokens[1].token).email
  const link = getLoggedInRoute(router, userEmail)
  sendGAEvent('event', 'login', {
    user_id: getUserId()
  })
  router.push(link)
}

const getLoggedInRoute = (router: NextRouter, email: string): string => {
  if (LEADS_RESTRICTED_USER_EMAILS.includes(email)) return getNextRoute('leads')

  return router.query.returnTo ?? getNextRoute('discount')
}

export const navigateUserToChangePassword = (
  data: { login: LoginPayload },
  email: string,
  router: NextRouter
): void => {
  router.push({
    pathname: getNextRoute('changePassword'),
    query: {
      email,
      sessionToken: data.login.sessionToken,
      challengeName: data.login.authChallenge
    }
  })
}

export const createTokenArray = (
  data: LoginPayload
): { name: string; token: Maybe<string> | undefined }[] => {
  const tokens = [
    {
      name: ACCESS_TOKEN_NAME,
      token: data.accessToken
    },
    {
      name: ID_TOKEN_NAME,
      token: data.idToken
    }
  ]

  return tokens
}

export const logUserOut = (router: NextRouter): void => {
  ;[ACCESS_TOKEN_NAME, ID_TOKEN_NAME].forEach((tokenName) => {
    localStorage.removeItem(tokenName)
  })

  router.push(getNextRoute('login'))
}

export const isTokenValid = (token: string): boolean => {
  const decodedJwt = jwtDecode<IdToken>(token)
  const UTCNow = Math.floor(new Date().getTime() / 1000)
  const expiryDate = decodedJwt?.exp

  return expiryDate > UTCNow
}

export const isProductionTestingAccount = (email: string): boolean => {
  return email === PRODUCTION_TESTING_ACCOUNT
}

export const getUserId = (): Nullable<string> => {
  const token = getToken(ID_TOKEN_NAME)

  if (token) {
    const { sub } = jwtDecode<IdToken>(token)

    return sub
  }

  return null
}
