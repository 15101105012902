import Link from 'next/link'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import Typography from '@thebeansgroup/ui/typography'

import PionButton from '@components/pion_button'

import * as styles from './styles'

const HeaderButton = ({ route, title, icon, action, target }) => {
  const renderIcon = () => {
    if (!icon) return null

    return icon
  }

  const renderContent = () => {
    return (
      <Fragment>
        {renderIcon()}
        <Typography
          variant='subhead'
          colour='white-300'
        >
          {title}
        </Typography>
      </Fragment>
    )
  }

  const renderRouteWidget = () => {
    if (!route) return null

    return (
      <Link
        css={styles.container}
        href={route}
        target={target}
      >
        {renderContent()}
      </Link>
    )
  }

  const renderActionWidget = () => {
    if (!action) return null

    return (
      <PionButton
        customStyles={styles.container}
        onClick={action}
      >
        {renderContent()}
      </PionButton>
    )
  }

  return (
    <div>
      {renderActionWidget()}
      {renderRouteWidget()}
    </div>
  )
}

HeaderButton.propTypes = {
  route: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  action: PropTypes.func,
  target: PropTypes.string
}

HeaderButton.defaultProps = {
  icon: null,
  route: null,
  action: null,
  target: '_self'
}

export default HeaderButton
