export const PAGE_LABEL_PROPS = [
  'Analytics',
  'Settings',
  'Marketplace',
  'Discounts',
  'Leads',
  'Upgrades',
  'Terms and Conditions',
  'Integrations',
  'Setup',
  'Blog Post',
  ''
]
