import { useContext, useEffect, useState } from 'react'

import { UnstyledButton } from '@thebeansgroup/ui_ctas'
import CloseIcon from '@thebeansgroup/ui_icons/close_icon'
import Body from '@thebeansgroup/ui_typography/body'

import { ToastContext } from '@root/src/contexts/toast/provider'

import { ANIMATION_TIME, CLOSE_TIME, TOAST_TYPES_COLOURS } from './constants'
import * as styles from './styles'

const Toast = () => {
  const [closing, setClosing] = useState(false)
  const { toast, removeToast } = useContext(ToastContext)

  useEffect(() => {
    const triggerCloseAnimation = () => {
      setTimeout(() => {
        setClosing(true)

        resetToast()
      }, CLOSE_TIME)
    }

    const resetToast = () => {
      setTimeout(() => {
        removeToast()
        setClosing(false)
      }, ANIMATION_TIME)
    }

    if (toast) {
      triggerCloseAnimation()
    }
  }, [toast, removeToast])

  return (
    <div
      data-testid='toast'
      css={[
        styles.toast(toast?.type),
        toast && styles.active,
        closing && styles.closing
      ]}
    >
      <Body colour={TOAST_TYPES_COLOURS[toast?.type]?.text}>{toast?.text}</Body>
      <UnstyledButton
        onClick={removeToast}
        css={styles.close}
      >
        <CloseIcon
          colour={TOAST_TYPES_COLOURS[toast?.type]?.text}
          width='12px'
          height='12px'
        />
      </UnstyledButton>
    </div>
  )
}

export default Toast
