import { captureException, withScope } from '@sentry/nextjs'
import PropTypes from 'prop-types'
import { Component } from 'react'

import Banner from '@components/banner'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    withScope((scope) => {
      scope.setExtras(errorInfo)
      scope.setTag('portal_handler', 'ErrorBoundary')
      captureException(error)
    })
  }

  render() {
    if (!this.state.hasError) return this.props.children

    return (
      <div className='w-full p-5'>
        <Banner
          text='There has been an error during this action, it has been reported and will be resolved as soon as possible.'
          type='danger'
        />
      </div>
    )
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired
}

export default ErrorBoundary
