export const CONFIG = {
  logo: {
    image: 'images/yms_logo.svg',
    height: 25,
    width: 63,
    alt: 'YMS logo'
  },
  title: `YMS LONDON - You’re invited! Get your exclusive free tickets and be a part of the world's largest youth marketing festival`,
  link: 'http://www.eventbrite.co.uk/e/914525669897/?discount=PORTAL',
  showArrowIcon: true,
  endDate: '2025-03-05T00:00:00Z'
}
