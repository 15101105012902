import { css } from '@emotion/react'

// eslint-disable-next-line @thebeansgroup/sb/no-hex-literals
const PRIMARY_COLOR = '#ff1c69'
// eslint-disable-next-line @thebeansgroup/sb/no-hex-literals
const HOVER_COLOR = '#cf0b4e'

export const bannerContainer = css`
  background-color: ${PRIMARY_COLOR};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${HOVER_COLOR};
  }
`

export const titleContainer = css`
  display: flex;
  justify-content: center;
  gap: 40px;
  padding: 8px 0;
`

export const logoContainer = css`
  position: relative;
  display: flex;
  align-items: center;
  margin: -15px;
`
