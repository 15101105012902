import { useState, createContext } from 'react'

import { TChildren, TToast, TToastContextProps } from './types'

export const ToastContext = createContext<TToastContextProps>({
  toast: null,
  removeToast: /* istanbul ignore next */ () => {},
  addToast: /* istanbul ignore next */ () => {}
})

export const ToastProvider = ({ children }: TChildren): React.ReactElement => {
  const [toast, setToast] = useState<TToast | null>(null)

  const removeToast = (): void => setToast(null)

  const addToast = (text: string, type: string): void =>
    setToast({ text, type })

  const contextValue = {
    toast,
    addToast: (message: string, status: string): void =>
      addToast(message, status),
    removeToast
  }

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  )
}
