import AnalyticsIcon from '@thebeansgroup/ui/icons/analytics'
import DottedMenuIcon from '@thebeansgroup/ui/icons/dotted_menu'
import GearIcon from '@thebeansgroup/ui/icons/gear'
import ResourcesIcon from '@thebeansgroup/ui/icons/resources'
import UpgradeIcon from '@thebeansgroup/ui/icons/upgrade'
import EyeIcon from '@thebeansgroup/ui_icons/eye_icon'
import HelpIcon from '@thebeansgroup/ui_icons/help_icon'
import LabelIcon from '@thebeansgroup/ui_icons/label_icon'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import Badge from '@components/badge'
import LeadsCount from '@components/leads_count'
import SafeImage from '@components/safe_image'
import { LEADS_RESTRICTED_USER_EMAILS } from '@constants/auth'
import { withAssetPrefix } from '@helpers/assets'
import { getNextRoute } from '@helpers/routes'

export const NAV_ITEMS = [
  {
    title: 'Setup',
    icon: () => <DottedMenuIcon className='fill-off-black-300 stroke-none' />,
    href: getNextRoute('setup'),
    restrictedEmails: LEADS_RESTRICTED_USER_EMAILS,
    showToggle: 'onboarding',
    badge: (
      <Badge
        type='Managed'
        variant='caption2'
      >
        NEW
      </Badge>
    )
  },
  {
    title: 'Analytics',
    icon: () => <AnalyticsIcon className='fill-off-black-300 stroke-none' />,
    href: getNextRoute('home'),
    restrictedEmails: LEADS_RESTRICTED_USER_EMAILS
  },
  {
    title: 'Discounts',
    icon: () => <LabelIcon colour={Colours('offBlack', 300)} />,
    href: getNextRoute('discount'),
    restrictedEmails: LEADS_RESTRICTED_USER_EMAILS
  },
  {
    title: 'Integrations',
    icon: () => (
      <SafeImage
        width={24}
        height={24}
        alt=''
        src={withAssetPrefix('images/integration_icon.svg')}
      />
    ),
    showToggle: 'integrations',
    href: getNextRoute('integrations'),
    restrictedEmails: LEADS_RESTRICTED_USER_EMAILS
  },
  {
    title: 'Upgrades',
    icon: () => <UpgradeIcon className='fill-off-black-300 stroke-none' />,
    showToggle: 'upgrades_page',
    href: getNextRoute('upgrades'),
    restrictedEmails: LEADS_RESTRICTED_USER_EMAILS
  },
  {
    title: 'Resources',
    icon: () => <ResourcesIcon className='stroke-none fill-off-black-300' />,
    showToggle: 'resources_page',
    href: getNextRoute('blog'),
    restrictedEmails: LEADS_RESTRICTED_USER_EMAILS
  }
]

export const LEADS_NAV_ITEM = {
  title: 'Leads',
  icon: () => <EyeIcon colour={Colours('offBlack', 300)} />,
  href: getNextRoute('leads'),
  badge: <LeadsCount />,
  restrictedEmails: []
}

export const BOTTOM_NAV_ITEMS = [
  {
    title: 'Settings',
    icon: () => <GearIcon className='fill-off-black-300 stroke-none' />,
    href: getNextRoute('settings'),
    showToggle: 'settings',
    restrictedEmails: []
  },
  {
    title: 'Support',
    icon: () => <HelpIcon colour={Colours('offBlack', 300)} />,
    href: getNextRoute('beansIdHelpCentre'),
    restrictedEmails: []
  }
]
