import Tooltip from '@mui/material/Tooltip'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import { Fragment } from 'react'

import Typography from '@thebeansgroup/ui/typography'

import { NullableReactElement } from '@root/src/typings/utils'

import PionButton from '@components/pion_button'
import SafeImage from '@components/safe_image'
import { baseStyles } from '@constants/tooltip'
import FeatureToggle from '@helpers/feature_toggle'
import { getNextRoute } from '@helpers/routes'

import { IMAGE_HEIGHT, IMAGE_WIDTH, SETUP_TEXT, TOOLTIP } from './constants'
import * as styles from './styles'
import { EmptyTableProps } from './types'

const EmptyTable = ({
  title,
  description,
  buttonText,
  buttonAction,
  imgSrc,
  navigateToSetup = false
}: EmptyTableProps): React.ReactElement => {
  const pathname = usePathname()
  const router = useRouter()

  const renderImage = (): NullableReactElement => {
    if (!imgSrc) return null

    return (
      <div>
        <SafeImage
          alt=''
          src={imgSrc}
          width={IMAGE_WIDTH}
          height={IMAGE_HEIGHT}
        />
      </div>
    )
  }

  const renderTooltipContent = (): React.ReactElement => (
    <div css={styles.tooltipContainer}>
      <Typography
        variant='headline'
        component='h2'
        colour='white-300'
      >
        {TOOLTIP.TITLE}
      </Typography>
      <Typography
        variant='subhead'
        colour='white-300'
      >
        {TOOLTIP.DESCRIPTION}
      </Typography>
    </div>
  )

  const renderSetupButton = (): NullableReactElement => {
    if (!FeatureToggle.isEnabled('onboarding') || !navigateToSetup) return null

    return (
      <PionButton
        type='secondary'
        onClick={() =>
          router.push({
            pathname: getNextRoute('setup')
          })
        }
        customStyles={styles.tableButton}
      >
        {SETUP_TEXT}
      </PionButton>
    )
  }

  const renderButton = (): React.ReactElement => {
    if (pathname === getNextRoute('discount')) {
      return (
        <Tooltip
          arrow
          open={true}
          title={renderTooltipContent()}
          componentsProps={baseStyles}
        >
          <div>
            <PionButton
              onClick={buttonAction}
              customStyles={styles.tableButton}
            >
              {buttonText}
            </PionButton>
          </div>
        </Tooltip>
      )
    }

    return (
      <PionButton
        onClick={buttonAction}
        customStyles={styles.tableButton}
      >
        {buttonText}
      </PionButton>
    )
  }

  return (
    <Fragment>
      <div css={styles.blankHeader} />
      <div css={styles.blankTable}>
        <div css={styles.blankMessage}>
          {renderImage()}
          <Typography
            variant='headline'
            component='h3'
            colour='off-black-300'
          >
            {title}
          </Typography>
          <Typography
            variant='callout'
            colour='off-black-300'
          >
            {description}
          </Typography>
          <div className='flex-center gap-4'>
            {renderSetupButton()}
            {renderButton()}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default EmptyTable
