import { gql } from '@apollo/client'

export const GET_LEADS_COUNT_QUERY = gql`
  query LeadsCountQuery($brandUids: [String!]!) {
    cognitoViewer {
      leads(brandUids: $brandUids) {
        idCode
      }
    }
  }
`
