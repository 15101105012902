import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const userMenuHolder = (width, reverse) => css`
  right: ${reverse ? 0 : null};
  position: absolute;
  width: ${width};
  z-index: 5;
  border: 1px solid ${Colours('grey', 100)};
  background-color: ${Colours('white')};
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 1px 10px 0px rgba(7, 31, 69, 0.05);
  display: flex;
  flex-direction: column;
`
