import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const userIcon = css`
  background-color: ${Colours('zest', 100)};
  border-radius: 8px;
  padding: 8px;
  display: flex;
`
