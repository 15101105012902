import Typography from '@thebeansgroup/ui/typography'

import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'
import { isEventExpired } from '@helpers/event_banner'
import { NullableReactElement } from '@typings/utils'

import * as styles from './styles'
import { EventBannerProps } from './types'

const EventBanner = ({ config }: EventBannerProps): NullableReactElement => {
  const renderLogo = (): React.ReactElement => {
    return (
      <div css={styles.logoContainer}>
        <SafeImage
          height={config.logo.height}
          width={config.logo.width}
          alt={config.logo.alt}
          src={withAssetPrefix(config.logo.image)}
        />
      </div>
    )
  }

  const renderTitle = (): React.ReactElement => {
    return (
      <Typography
        variant='subhead'
        colour='white-300'
        component='h5'
        fontWeight='semibold'
      >
        {config.title}
      </Typography>
    )
  }

  if (isEventExpired(config.endDate)) return null

  return (
    <div css={styles.bannerContainer}>
      <a
        href={config.link}
        target='_blank'
      >
        <div css={styles.titleContainer}>
          {renderLogo()}
          {renderTitle()}
        </div>
      </a>
    </div>
  )
}

export default EventBanner
