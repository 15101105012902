import { motion } from 'framer-motion'

import { TRANSITION } from './constants'
import { PopoverAnimationProps } from './types'

const PopoverAnimation = ({
  children
}: PopoverAnimationProps): React.ReactElement => {
  const fadeOut = { opacity: 0, y: -20 }
  const fadeIn = { opacity: 1, y: 0 }

  return (
    <motion.div
      initial={fadeOut}
      animate={fadeIn}
      transition={TRANSITION}
      className='w-full absolute z-5'
    >
      {children}
    </motion.div>
  )
}

export default PopoverAnimation
