import PropTypes from 'prop-types'

import UnstyledButton from '@thebeansgroup/ui_ctas/unstyled_button'

import * as styles from './styles'

const MobileNavBackground = ({ mobileNavOpen, toggle }) => {
  return (
    <UnstyledButton
      css={[
        styles.mobileBackground,
        mobileNavOpen && styles.mobileBackgroundActive
      ]}
      data-testid='mobile-nav-background'
      onClick={toggle}
    />
  )
}

MobileNavBackground.propTypes = {
  mobileNavOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired
}

MobileNavBackground.defaultProps = {
  mobileNavOpen: false
}

export default MobileNavBackground
