import Script from 'next/script'
import { Fragment, useEffect, useState } from 'react'

import { getUserId } from '@helpers/auth'

const Survicate = () => {
  const [userId, setUserId] = useState(null)
  useEffect(() => {
    if (!userId && localStorage) {
      const userId = getUserId()
      setUserId(userId)
    }
  }, [userId])

  const renderScripts = () => {
    if (!userId) return null

    const survicateId = process?.env?.NEXT_PUBLIC_SURVICATE_WORKSPACE_ID

    return (
      <Fragment>
        <Script
          id='survicate-user'
          data-testid='survicate'
        >
          {(function (opts) {
            opts.traits = {
              user_id: userId
            }
          })((window._sva = window._sva || {}))}
        </Script>

        <Script
          data-testid='survicate'
          type='text/javascript'
          src={`https://survey.survicate.com/workspaces/${survicateId}/web_surveys.js`}
          strategy='afterInteractive'
        />
      </Fragment>
    )
  }

  return renderScripts()
}

export default Survicate
