import { useRouter } from 'next/router'

import { PopoverMenuItemProps } from './types'

const PopoverMenuItem = ({
  title = '',
  link = '',
  buttonAction = null,
  children = null
}: PopoverMenuItemProps): React.ReactElement => {
  const { push } = useRouter()

  const handleButtonClick = (): void => {
    if (link) {
      push(link)
    }

    if (buttonAction) {
      buttonAction()
    }
  }

  return (
    <button
      className='py-[12px] px-[9px] border-none rounded-1 text-left bg-white-300 duration-200 ease-in-out hover:bg-neutral-25 '
      onClick={handleButtonClick}
    >
      {title || children}
    </button>
  )
}

export default PopoverMenuItem
