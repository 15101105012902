import { css, keyframes, SerializedStyles } from '@emotion/react'

const placeholderShimmer = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateX(280%);
    opacity: 0.5;
  }
`

export const animationOverlay = (
  left: string,
  width: string
): SerializedStyles => css`
  position: absolute;
  height: 100%;
  width: ${width};
  top: 0;
  left: ${left};
  background: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  overflow: hidden;
  animation-name: ${placeholderShimmer};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`
