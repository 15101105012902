import PropTypes from 'prop-types'
import React from 'react'

import UnstyledButton from '@thebeansgroup/ui_ctas/unstyled_button'
import MenuIcon from '@thebeansgroup/ui_icons/menu_icon'

import SafeImage from '@components/safe_image'
import User from '@components/user'
import { withAssetPrefix } from '@helpers/assets'

import { PION_LOGO_ALT_TEXT } from './constants'
import * as styles from './styles'

const Header = ({ toggle }) => {
  const renderMobileIcon = () => {
    return (
      <UnstyledButton
        css={styles.imageContainerMobile}
        onClick={toggle}
      >
        <MenuIcon
          colour={'white'}
          height={'15'}
          width={'15'}
        />
      </UnstyledButton>
    )
  }

  const renderDesktopImage = () => {
    return (
      <div css={styles.imageContainerDesktop}>
        <SafeImage
          height={28}
          width={200}
          alt={PION_LOGO_ALT_TEXT}
          src={withAssetPrefix('images/pion-branded-logo.svg')}
        />
      </div>
    )
  }

  return (
    <div
      css={styles.header}
      data-testid='header'
    >
      {renderDesktopImage()}
      {renderMobileIcon()}
      <div css={styles.userContainer}>
        <User />
      </div>
    </div>
  )
}

Header.propTypes = {
  toggle: PropTypes.func.isRequired
}

export default Header
