import Hotjar from '@hotjar/browser'
import { ThemeProvider } from '@mui/material'
import { GoogleAnalytics } from '@next/third-parties/google'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Fragment, useEffect } from 'react'

import '@styles/globals.css'

import { CreateDataProvider } from '@root/src/contexts/create_data/provider'
import { ToastProvider } from '@root/src/contexts/toast/provider'
import MuiTheme from '@src/mui_theme'

import Auth from '@components/auth'
import ErrorBoundary from '@components/error_boundary'
import Metadata from '@components/metadata'
import OrganisationsProviderRenderer from '@components/organisations_provider_renderer'
import Survicate from '@components/survicate'
import { isProduction } from '@helpers/env'

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter()

  useEffect(() => {
    const id = process?.env?.NEXT_PUBLIC_HOTJAR_ID
    const version = process?.env?.NEXT_PUBLIC_HOTJAR_VERSION

    if (id && version) {
      Hotjar.init(JSON.parse(id), JSON.parse(version))
    }
  }, [])

  const renderGoogleAnalytics = () => {
    if (!isProduction()) return null

    return (
      <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />
    )
  }

  return (
    <Fragment>
      {renderGoogleAnalytics()}
      <Metadata />
      <ErrorBoundary>
        <ThemeProvider theme={MuiTheme}>
          <ToastProvider>
            <Auth>
              <AnimatePresence
                mode='wait'
                initial={false}
              >
                <CreateDataProvider>
                  <OrganisationsProviderRenderer>
                    <Component
                      {...pageProps}
                      key={router.pathname}
                    />
                  </OrganisationsProviderRenderer>
                </CreateDataProvider>
              </AnimatePresence>
              <Survicate />
            </Auth>
          </ToastProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </Fragment>
  )
}

MyApp.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.shape({
    translations: PropTypes.object
  }).isRequired
}

export default MyApp
