import { ACCESS_TOKEN_NAME } from '@constants/auth'
import { getToken, isTokenValid } from '@helpers/auth'
import { getNextRoute } from '@helpers/routes'

export const isUserAuthenticated = () => {
  const token = getToken(ACCESS_TOKEN_NAME)

  if (token) {
    return isTokenValid(token)
  }

  return false
}

export const sendUserToLoginScreen = (push, pathname) => {
  const returnParam = `?returnTo=${pathname}`
  const loginRoute = getNextRoute('login')
  push(`${loginRoute}${returnParam}`)
}

export const isRouteLogin = (pathname, loginRoute) => {
  return pathname?.includes(loginRoute)
}
