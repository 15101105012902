import Head from 'next/head'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import { withAssetPrefix } from '@helpers/assets'

import { PION_FAVICON, PION_TITLE } from './constants'
import { getRobotsContent } from './helpers'

const Metadata = ({ description, robots }) => {
  const renderDescription = () => {
    if (!description) return null

    return (
      <meta
        content={description}
        name='description'
        key='description'
      />
    )
  }

  const renderRobots = () => {
    const robotsContent = getRobotsContent(robots)
    if (!robotsContent) return null

    return (
      <meta
        content={robotsContent}
        name='robots'
        key='robots'
      />
    )
  }

  return (
    <Fragment>
      <Head>
        <title key='title'>{PION_TITLE}</title>
        {renderDescription()}
        {renderRobots()}
        <link
          href={withAssetPrefix(PION_FAVICON)}
          rel='icon'
          media='(prefers-color-scheme: light)'
        />
        <link
          href={withAssetPrefix(PION_FAVICON)}
          rel='icon'
          media='(prefers-color-scheme: dark)'
        />
      </Head>
    </Fragment>
  )
}

Metadata.propTypes = {
  description: PropTypes.string,
  robots: PropTypes.arrayOf(PropTypes.string)
}

Metadata.defaultProps = {
  description: '',
  robots: []
}

export default Metadata
