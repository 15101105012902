import { AnimatePresence } from 'framer-motion'
import { Fragment, useContext, useState } from 'react'

import Typography from '@thebeansgroup/ui/typography'
import { UnstyledButton } from '@thebeansgroup/ui_ctas'
import ChevronIconThin from '@thebeansgroup/ui_icons/chevron_icon_thin'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import { OrganisationsContext } from '@root/src/contexts/organisations/provider'

import Badge from '@components/badge'
import Placeholder from '@components/placeholder'
import PopoverMenu from '@components/popover_menu'
import PopoverMenuItem from '@components/popover_menu_item'

import {
  ORG_MENU_TEST_ID,
  PRICING_TIER_MAPPING,
  SELECTED_ORG_TEST_ID
} from './constants'
import { getCompanyInitials } from './helpers'
import * as styles from './styles'
const OrganisationSelector = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const {
    organisationsData,
    changeSelectedOrganisation,
    selectedOrganisation
  } = useContext(OrganisationsContext)

  const toggleMenuOpen = () => {
    if (!organisationsData) return

    setMenuOpen(!menuOpen)
  }

  const handleOrganisationSelection = (organisation) => {
    changeSelectedOrganisation(organisation)
    setMenuOpen(false)
  }

  const renderDropdownIcon = () => {
    if (!organisationsData) return null

    return (
      <div css={styles.dropdownIcon}>
        <ChevronIconThin
          height='12px'
          width='12px'
          colour={Colours('offBlack', 300)}
        />
      </div>
    )
  }

  const renderIconText = (organisation) => {
    if (!organisation)
      return (
        <Placeholder
          rounded
          height='30px'
          width='30px'
        />
      )

    return (
      <Typography
        variant='caption1'
        colour='white-300'
      >
        {getCompanyInitials(organisation?.companyName)}
      </Typography>
    )
  }

  const renderCompanyName = (organisation) => {
    if (!organisation || !selectedOrganisation)
      return (
        <Placeholder
          rounded
          height='18px'
        />
      )

    const selected =
      organisation.companyName === selectedOrganisation.companyName

    return (
      <Typography
        variant='callout'
        fontWeight={selected ? 'semibold' : 'normal'}
        colour='off-black-300'
      >
        {organisation?.companyName}
      </Typography>
    )
  }

  const renderSelectedIcon = (organisation) => {
    return (
      <Badge type={PRICING_TIER_MAPPING[organisation.pricingTier]}>
        {organisation.pricingTier}
      </Badge>
    )
  }

  const renderMenu = () => {
    if (!organisationsData) return null

    return (
      <AnimatePresence>
        <PopoverMenu
          testid={ORG_MENU_TEST_ID}
          menuOpen={menuOpen}
          width='fit-content'
        >
          {organisationsData.map((organisation) => (
            <PopoverMenuItem
              key={organisation.id}
              buttonAction={() => handleOrganisationSelection(organisation)}
            >
              <div css={styles.menuItem}>
                <div className='flex items-center truncate gap-4'>
                  <div
                    css={[
                      styles.organisationIcon,
                      organisationsData && styles.iconBackground
                    ]}
                  >
                    {renderIconText(organisation)}
                  </div>
                  {renderCompanyName(organisation)}
                </div>
                {renderSelectedIcon(organisation)}
              </div>
            </PopoverMenuItem>
          ))}
        </PopoverMenu>
      </AnimatePresence>
    )
  }

  return (
    <Fragment>
      <UnstyledButton
        data-testid={SELECTED_ORG_TEST_ID}
        css={styles.organisationSelector}
        onClick={toggleMenuOpen}
      >
        <div
          css={[
            styles.organisationIcon,
            organisationsData && styles.iconBackground
          ]}
        >
          {renderIconText(selectedOrganisation)}
        </div>
        {renderCompanyName(selectedOrganisation)}
        {renderDropdownIcon()}
      </UnstyledButton>
      {renderMenu()}
    </Fragment>
  )
}

export default OrganisationSelector
