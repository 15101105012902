import Tooltip from '@mui/material/Tooltip'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import Typography from '@thebeansgroup/ui/typography'
import UnstyledButton from '@thebeansgroup/ui_ctas/unstyled_button'
import CloseIcon from '@thebeansgroup/ui_icons/close_icon'
import Colours from '@thebeansgroup/ui_style_helpers/colours'

import { baseStyles } from '@constants/tooltip'
import { removeTooltipItem } from '@helpers/notifications'
import { NullableReactElement } from '@typings/utils'

import { getTargetProp, shouldRenderToolTip } from './helpers'
import { NavItemProps } from './types'

const NavItem = ({
  link,
  title,
  icon,
  badge = null,
  notification = false,
  removeTooltip
}: NavItemProps): React.ReactElement => {
  const pathname = usePathname()
  const selected = link === pathname

  const renderBadge = (): NullableReactElement => {
    if (!badge) return null

    return <div className='ml-auto'>{badge}</div>
  }

  const handleTooltipClose = (event: React.ReactEventHandler): void => {
    removeTooltipItem(link)
    removeTooltip(event, link)
  }

  const renderTooltipContent = (): React.ReactElement => {
    return (
      <div className='flex text-center gap-1 p-4'>
        <div>
          <Typography
            variant='headline'
            component='h2'
            colour='white-300'
          >
            {'🎉 Integrate your discount'}
          </Typography>
          <Typography
            variant='subhead'
            colour='white-300'
          >
            {'Add your new discount to your website to increase sales'}
          </Typography>
        </div>
        <UnstyledButton
          onClick={(event: React.ReactEventHandler) =>
            handleTooltipClose(event)
          }
        >
          <CloseIcon
            width={'12px'}
            height={'12px'}
            colour={Colours('white')}
          />
        </UnstyledButton>
      </div>
    )
  }

  const renderNotification = (): NullableReactElement => {
    if (!notification || selected) return null

    return (
      <span
        data-testid='notification'
        className='absolute rounded-[50%] w-2 h-2 left-1 bg-alert-blue-300'
      />
    )
  }

  return (
    <Tooltip
      arrow
      placement='right'
      open={shouldRenderToolTip(link)}
      title={renderTooltipContent()}
      componentsProps={baseStyles}
    >
      <Link
        href={link}
        passHref
        className={`relative flex items-center my-1 mx-5 cursor-pointer ${selected ? 'bg-neutral-50 border-l-[3px] border-l-solid border-offBlack-300' : 'hover:bg-neutral-25'} rounded-r-[5px] p-[15px_18px]`}
        target={getTargetProp(link)}
      >
        {renderNotification()}
        <div className='flex mr-5'>{icon(selected)}</div>
        {title}
        {renderBadge()}
      </Link>
    </Tooltip>
  )
}

export default NavItem
