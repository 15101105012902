import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const baseStyles = {
  tooltip: {
    sx: {
      bgcolor: Colours('colbalt', 400),
      '& .MuiTooltip-arrow': {
        color: Colours('colbalt', 400)
      },
      borderRadius: '10px'
    }
  }
}
