import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import {
  desktop,
  mobile,
  sister
} from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

import { HEADER_HEIGHT } from '@constants/layout'

export const header = css`
  background-color: ${Colours('offBlack', 300)};
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT};
  padding: 16px 26px;
`

export const imageContainerDesktop = css`
  position: relative;
  display: flex;
  align-items: center;
  margin: -15px;

  ${mobile} {
    display: none;
  }

  ${sister} {
    display: none;
  }
`

export const imageContainerMobile = css`
  position: relative;
  display: flex;
  width: fit-content;
  padding: 12px;
  border-style: solid;
  border-color: ${Colours('white')};
  border-width: 0.5px;
  border-radius: 8px;
  align-items: center;

  ${desktop} {
    display: none;
  }
`

export const userContainer = css`
  margin-left: auto;
`
