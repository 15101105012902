/* eslint-disable @thebeansgroup/sb/file-naming-convention */
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import fetch from 'cross-fetch'

import { ACCESS_TOKEN_NAME, ID_TOKEN_NAME } from '@constants/auth'

const httpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_GRAPHQL_URL}/graphql/v1/query`,
  fetch
})

const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME)
  const idToken = localStorage.getItem(ID_TOKEN_NAME)

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
      idToken: idToken || ''
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
})
