import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const count = css`
  background-color: ${Colours('neutral', 25)};
  padding: 0 8px;
  border-radius: 5px;
`

export const loadingContainer = css`
  padding: 5px 0px;
`
