import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'

import { OrganisationsContext } from '@root/src/contexts/organisations/provider'
import { useIdToken } from '@root/src/hooks/use_id_token'

import Banner from '@components/banner'
import EmptyTable from '@components/empty_table'
import QuicksightPlaceholder from '@components/quicksight_embed/placeholder'
import { withAssetPrefix } from '@helpers/assets'
import { logUserOut } from '@helpers/auth'
import { getNextRoute } from '@helpers/routes'
import { useLazyQuery } from '@hooks/use_lazy_query'

import {
  EMPTY_ANALYTICS,
  FALLBACK_EMBED_ERROR,
  GET_DASHBOARD_QUERY,
  INTERNAL_DASHBOARD_ERROR
} from './constants'
import {
  getDashboardUrl,
  hasTokenExpired,
  getDashboardId,
  clearDashboard,
  shouldRenderDashboard,
  handleMessage,
  logError
} from './helpers'
import * as styles from './styles'

const QuicksightEmbed = () => {
  const [isQuicksightEmbedded, setIsQuicksightEmbedded] = useState(false)
  const [dashboardUrl, setDashboardUrl] = useState('')
  const [dashboardId, setDashboardId] = useState('')
  const [dashboardError, setDashboardError] = useState('')
  const [dashboardEmpty, setDashboardEmpty] = useState(false)
  const { selectedOrganisation } = useContext(OrganisationsContext)
  const { email } = useIdToken()

  const router = useRouter()

  const [getDashboard, { data, errors }] = useLazyQuery(GET_DASHBOARD_QUERY)

  useEffect(() => {
    if (selectedOrganisation) {
      const dbid = getDashboardId(selectedOrganisation)

      if (!shouldRenderDashboard(selectedOrganisation)) {
        return setDashboardError(INTERNAL_DASHBOARD_ERROR)
      }

      if (!dbid) {
        setDashboardEmpty(true)
      } else {
        setDashboardEmpty(false)
      }

      if (dbid !== dashboardId) {
        setDashboardUrl('')
        setIsQuicksightEmbedded(false)
        setDashboardError('')

        clearDashboard()
        setDashboardId(dbid)
      }
    }
  }, [selectedOrganisation, dashboardId, email])

  useEffect(() => {
    if (dashboardId) {
      getDashboard({
        variables: {
          dashboardId
        }
      })
    }
  }, [getDashboard, dashboardId])

  useEffect(() => {
    if (data) {
      setDashboardUrl(getDashboardUrl(data))
    }
  }, [data])

  useEffect(() => {
    const createQuickSightDashboard = async () => {
      const embeddingContext = await createEmbeddingContext()

      const { embedDashboard } = embeddingContext

      /* istanbul ignore next */
      const frameOptions = {
        url: dashboardUrl,
        container: document.getElementById('quicksight-holder'),
        height: '1500px',
        width: '100%',
        resizeHeightOnSizeChangedEvent: true,
        withIframePlaceholder: true,
        onChange: (changeEvent) => {
          handleMessage(changeEvent, email)
          console.log(JSON.stringify(changeEvent))
        }
      }

      /* istanbul ignore next */
      const contentOptions = {
        onMessage: async (messageEvent) => handleMessage(messageEvent, email)
      }

      embedDashboard(frameOptions, contentOptions)
        .then(() => {
          setIsQuicksightEmbedded(true)
        })
        .catch((error) => {
          const fallbackError = FALLBACK_EMBED_ERROR
          logError(error || fallbackError, email)
        })
    }

    if (dashboardUrl) {
      createQuickSightDashboard()
    }
  }, [dashboardUrl, email])

  useEffect(() => {
    if (hasTokenExpired(errors)) {
      logUserOut(router)
    } else if (errors) {
      logError(errors, email)
    }
  }, [errors, router, email])

  const renderQuicksightLoading = () => {
    if (isQuicksightEmbedded || dashboardError || dashboardEmpty) return false

    return <QuicksightPlaceholder />
  }

  const renderQuicksightError = () => {
    if (!dashboardError) return null

    return (
      <Banner
        type='warning'
        text={dashboardError}
      />
    )
  }

  const renderEmptyState = () => {
    if (!dashboardEmpty) return null

    return (
      <EmptyTable
        title={EMPTY_ANALYTICS.TITLE}
        description={EMPTY_ANALYTICS.DESCRIPTION}
        buttonText={EMPTY_ANALYTICS.BUTTON_TEXT}
        buttonAction={() => {
          router.push({
            pathname: getNextRoute('discount')
          })
        }}
        imgSrc={withAssetPrefix('images/empty_analytics.png')}
      />
    )
  }

  return (
    <div
      css={styles.quicksightHolder}
      id='quicksight-holder'
    >
      {renderQuicksightLoading()}
      {renderQuicksightError()}
      {renderEmptyState()}
    </div>
  )
}

export default QuicksightEmbed
