import jwtDecode from 'jwt-decode'

import { ID_TOKEN_NAME } from '@constants/auth'
import { getToken } from '@helpers/auth'

export const useIdToken = () => {
  const jwtToken = getToken(ID_TOKEN_NAME)

  const decodedJwt = jwtDecode(jwtToken)

  const email = decodedJwt?.email
  const userId = decodedJwt?.sub

  return { email, userId }
}
