import Page from '@components/page'
import PageLayout from '@components/page_layout'
import QuicksightEmbed from '@components/quicksight_embed'
import { ANALYTICS_PAGE_LABEL } from '@constants/feature_toggles/analytics'

const Analytics = () => (
  <Page pageLabel={ANALYTICS_PAGE_LABEL}>
    <PageLayout
      title='Analytics'
      cardStyling={false}
    >
      <QuicksightEmbed />
    </PageLayout>
  </Page>
)

export default Analytics
