import root from 'window-or-global'

import {
  setup,
  trackPageView as tptTrackPageView,
  trackSelfDescribingEvent as tptTrackSelfDescribingEvent,
  trackStructEvent as tptTrackStructEvent,
  setUserId,
  addGlobalContexts as tptAddGlobalContexts,
  removeGlobalContexts as tptRemoveGlobalContexts
} from '@thebeansgroup/third_party_tracking/snowplow'

import { ORGANISATIONS_SNOWPLOW_SCHEMA } from '@constants/organisations'
import { SNOWPLOW_IS_SETUP } from '@constants/snowplow'
import {
  getOrganisationsContext,
  organisationHasCorrectBrandData
} from '@helpers/snowplow'

export const setupSnowplow = (userId) => {
  setup(
    process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_ENDPOINT,
    process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID,
    process.env.NEXT_PUBLIC_SNOWPLOW_COOKIE_DOMAIN
  )
  setUserId(userId)
  document.dispatchEvent(new root.CustomEvent(SNOWPLOW_IS_SETUP))
}

export const trackPageView = (title = null, customContexts = []) => {
  tptTrackPageView({ title, context: customContexts })
}

export const trackUnstructEvent = (schema, data, contexts) => {
  tptTrackSelfDescribingEvent({
    event: {
      schema,
      data
    },
    context: contexts
  })
}

export const trackStructEvent = (
  category,
  action,
  label,
  property,
  value,
  contexts
) => {
  tptTrackStructEvent({
    category,
    action,
    label,
    property,
    value
  })
}

export const removeOrganisationGlobalContext = (
  previousSelectedOrganisation
) => {
  const context = {
    schema: ORGANISATIONS_SNOWPLOW_SCHEMA,
    data: getOrganisationsContext(previousSelectedOrganisation)
  }

  tptRemoveGlobalContexts([context])
}

export const addOrganisationGlobalContext = (selectedOrganisation) => {
  const organisationData = getOrganisationsContext(selectedOrganisation)

  if (organisationHasCorrectBrandData(organisationData)) {
    const context = {
      schema: ORGANISATIONS_SNOWPLOW_SCHEMA,
      data: organisationData
    }

    tptAddGlobalContexts([context])
  }
}
