import Typography from '@thebeansgroup/ui/typography'
import AttentionCircleIcon from '@thebeansgroup/ui_icons/attention_circle_icon'
import InfoCircleIcon from '@thebeansgroup/ui_icons/info_circle_icon'
import TickCircleIcon from '@thebeansgroup/ui_icons/tick_circle_icon'

import {
  DANGER_BANNER_TYPE,
  INFO_BANNER_TYPE,
  SUCCESS_BANNER_TYPE,
  WARNING_BANNER_TYPE
} from '@constants/banner'
import { NullableReactElement } from '@typings/utils'

import {
  TYPE_COLOURS,
  ICON_HEIGHT,
  ICON_WIDTH,
  TAIL_WIND_TYPE_COLOURS
} from './constants'
import * as styles from './styles'
import { BannerProps } from './types'

const Banner = ({ text, type }: BannerProps): NullableReactElement => {
  const renderInfoIcon = (): React.ReactElement => {
    return (
      <InfoCircleIcon
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        colour={TYPE_COLOURS.info.contentColour}
        label={`${INFO_BANNER_TYPE} icon`}
      />
    )
  }

  const renderSuccessIcon = (): React.ReactElement => {
    return (
      <TickCircleIcon
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        colour={TYPE_COLOURS.success.contentColour}
        label={`${SUCCESS_BANNER_TYPE} icon`}
      />
    )
  }

  const renderWarningIcon = (): React.ReactElement => {
    return (
      <InfoCircleIcon
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        colour={TYPE_COLOURS.warning.contentColour}
        label={`${WARNING_BANNER_TYPE} icon`}
      />
    )
  }

  const renderDangerIcon = (): React.ReactElement => {
    return (
      <AttentionCircleIcon
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        colour={TYPE_COLOURS.danger.contentColour}
        label={`${DANGER_BANNER_TYPE} icon`}
      />
    )
  }

  const iconRenderMap = {
    [SUCCESS_BANNER_TYPE]: renderSuccessIcon(),
    [INFO_BANNER_TYPE]: renderInfoIcon(),
    [WARNING_BANNER_TYPE]: renderWarningIcon(),
    [DANGER_BANNER_TYPE]: renderDangerIcon()
  }

  if (!text || !type) return null

  return (
    <div
      css={styles.banner(
        TYPE_COLOURS[type].border,
        TYPE_COLOURS[type].background
      )}
    >
      <div css={styles.iconContainer}>{iconRenderMap[type]}</div>
      <div css={styles.text}>
        <Typography
          variant='footnote'
          colour={TAIL_WIND_TYPE_COLOURS[type].contentColour}
        >
          {text}
        </Typography>
      </div>
    </div>
  )
}

export default Banner
