import FeatureToggle from '@helpers/feature_toggle'
import { isGreenOrAmberGDPROrganisation } from '@helpers/organisations'

import { LEADS_NAV_ITEM, NAV_ITEMS } from './constants'

export const shouldRenderLeadsNavItem = (selectedOrganisation) => {
  return (
    isGreenOrAmberGDPROrganisation(selectedOrganisation) &&
    !!selectedOrganisation.brandUids.length
  )
}

export const getNavLinks = (selectedOrganisation, email) => {
  const items = [...NAV_ITEMS]

  if (!shouldRenderLeadsNavItem(selectedOrganisation)) return items

  return [...items, LEADS_NAV_ITEM]
}

export const shouldShowNotification = (notifications, href) => {
  return !!notifications.find((notificationRoute) => notificationRoute === href)
}

export const shouldShowLinkWithToggle = (toggle) => {
  if (!toggle) return true

  return FeatureToggle.isEnabled(toggle)
}
