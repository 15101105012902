const features = {
  create_brand: true, // FEATURE_TOGGLE 2025/01/31 Edit Discount @ditto
  integrations: true, // FEATURE_TOGGLE 2025/01/31  Integrations @ditto
  edit_integrations: true, // FEATURE_TOGGLE 2025/01/31  Integrations @ditto
  integration_create_type: false, // FEATURE_TOGGLE 2025/01/31  Integrations @ditto
  leads_upsell: true, // FEATURE_TOGGLE 2025/01/31  Integrations @ditto
  settings: true, // FEATURE_TOGGLE 2025/01/31  Settings @ditto
  image_improvements: true, // FEATURE_TOGGLE 2025/01/31  Settings @ditto
  winsday_upsell: true, // FEATURE_TOGGLE 2025/01/31  @ditto
  upgrades_page: true, // FEATURE_TOGGLE 2025/01/31  @ditto
  'discount_section:edit_page:premium_brand_labels': true, // FEATURE_TOGGLE 2025/01/31  CMS @ditto
  editorial_promo_blocks: true, // FEATURE_TOGGLE 2025/01/31  CMS @ditto
  campaign_markdown_content: true, // FEATURE_TOGGLE 2025/01/31  CMS @ditto
  campaign_evergreen: true, // FEATURE_TOGGLE 2025/01/31  CMS @ditto
  facets: false, // FEATURE_TOGGLE 2025/01/31  CMS @ditto
  promoGame: true, // FEATURE_TOGGLE 2025/01/31  CMS @ditto
  cms_routes: false, // FEATURE_TOGGLE 2025/01/31 CMS pages in Portal @rapidash
  hosted_integration_type: true, // FEATURE_TOGGLE 2025/01/31  Integrations @ditto
  onboarding: true, // FEATURE_TOGGLE 2025/01/31  Integrations @ditto
  resources_page: true, // FEATURE_TOGGLE 2025/01/31  Integrations @ditto
  category_benchmarking: false // FEATURE_TOGGLE 2025/02/31  Category benchmarking notifications @ditto
}

export default features
