import Colours from '@thebeansgroup/ui_style_helpers/colours'

import PlaceholderAnimation from '@components/placeholder_animation'

import * as styles from './styles'
import { PlaceholderProps } from './types'

const Placeholder = ({
  width = '100%',
  height,
  circular = false,
  rounded = false,
  colour = Colours('grey', 100)
}: PlaceholderProps): React.ReactElement => {
  return (
    <div
      role='presentation'
      aria-hidden='true'
      css={[
        styles.placeholder(width, height, circular, colour),
        rounded && styles.rounded
      ]}
    >
      <PlaceholderAnimation />
    </div>
  )
}

export default Placeholder
