import PropTypes from 'prop-types'

import PopoverAnimation from '@components/popover_animation'

import * as styles from './styles'

const PopoverMenu = ({ menuOpen, children, width, testid, reverse }) => {
  if (!menuOpen) return false

  return (
    <PopoverAnimation>
      <div
        data-testid={testid}
        css={styles.userMenuHolder(width, reverse)}
      >
        {children}
      </div>
    </PopoverAnimation>
  )
}

PopoverMenu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  toggleMenuOpen: PropTypes.func,
  width: PropTypes.string,
  testid: PropTypes.string,
  reverse: PropTypes.bool
}

PopoverMenu.defaultProps = {
  testid: '',
  toggleMenuOpen: null,
  width: '300px',
  reverse: false
}

export default PopoverMenu
