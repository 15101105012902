import {
  useLazyQuery as useApolloLazyQuery,
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  OperationVariables
} from '@apollo/client'

import { client } from '@root/apollo-client-csr'

export const useLazyQuery = <
  QueryReturnType,
  QueryVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<QueryReturnType, QueryVariables>
): LazyQueryResultTuple<QueryReturnType, QueryVariables> => {
  return useApolloLazyQuery(query, { ...options, client, errorPolicy: 'all' })
}
