import { captureException } from '@sentry/nextjs'
import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'

import { useIdToken } from '@root/src/hooks/use_id_token'

import {
  GET_ORGANISATION_QUERY,
  LOCAL_STORAGE_KEY
} from '@constants/organisations'
import { sendOrganisationGAData } from '@helpers/organisations'
import { useQuery } from '@hooks/use_query'
import {
  addOrganisationGlobalContext,
  removeOrganisationGlobalContext
} from '@tracker/snowplow'

export const OrganisationsContext = createContext({})

export const OrganisationsProvider = ({ children }) => {
  const [organisationsError, setOrganisationError] = useState(false)
  const [selectedOrganisation, setSelectedOrganisation] = useState(null)
  const [refetchOrganisation, setRefetchOrganisation] = useState(false)
  const { data, refetch } = useQuery(GET_ORGANISATION_QUERY)
  const { email } = useIdToken()

  useEffect(() => {
    if (data && (!selectedOrganisation || refetchOrganisation)) {
      setRefetchOrganisation(false)
      let organisation = data.cognitoViewer?.organisations?.[0]

      if (!organisation) {
        captureException(`${email} has no organisations`)
        setOrganisationError(true)
        return
      }

      const storedOrganisationId = localStorage.getItem(LOCAL_STORAGE_KEY)

      if (storedOrganisationId) {
        const orgFromStorage = data.cognitoViewer?.organisations.find(
          (organisation) => organisation.id === storedOrganisationId
        )

        if (orgFromStorage) {
          organisation = orgFromStorage
        }
      }

      setSelectedOrganisation(organisation)
      sendOrganisationGAData(organisation)
      addOrganisationGlobalContext(organisation)
    }
  }, [data, selectedOrganisation, email, refetchOrganisation])

  const changeSelectedOrganisation = (organisation) => {
    setSelectedOrganisation((prevState) => {
      removeOrganisationGlobalContext(prevState)
      return organisation
    })
    localStorage.setItem(LOCAL_STORAGE_KEY, organisation.id)
    sendOrganisationGAData(organisation)
    addOrganisationGlobalContext(organisation)
  }

  const updateOrganistions = () => {
    setRefetchOrganisation(true)
    refetch()
  }

  return (
    <OrganisationsContext.Provider
      value={{
        organisationsData: data?.cognitoViewer?.organisations,
        changeSelectedOrganisation,
        selectedOrganisation,
        organisationsError,
        updateOrganistions
      }}
    >
      {children}
    </OrganisationsContext.Provider>
  )
}

OrganisationsProvider.propTypes = {
  children: PropTypes.any.isRequired
}
