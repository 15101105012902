import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const pionButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 36px;
  height: 48px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
`

export const primary = css`
  color: ${Colours('white')};
  background-color: ${Colours('offBlack', 300)};

  &:not(:disabled) {
    &:hover {
      background-color: ${Colours('neutral', 500)};
    }

    &:active {
      background-color: ${Colours('neutral', 400)};
    }
  }

  &:disabled {
    background-color: ${Colours('neutral', 50)};
    color: ${Colours('neutral', 300)};
  }
`

export const secondary = css`
  color: ${Colours('offBlack', 300)};
  background-color: ${Colours('white')};
  border: 1px solid ${Colours('neutral', 100)};

  &:not(:disabled) {
    &:hover {
      background-color: ${Colours('neutral', 10)};
    }

    &:active {
      background-color: ${Colours('neutral', 100)};
    }
  }

  &:disabled {
    background-color: ${Colours('neutral', 50)};
    color: ${Colours('neutral', 300)};
    border: 1px solid ${Colours('neutral', 50)};
  }
`

export const error = css`
  color: ${Colours('white')};
  background-color: ${Colours('errorRed', 600)};
  border: 1px solid ${Colours('neutral', 100)};

  &:not(:disabled) {
    &:hover {
      background-color: ${Colours('errorRed', 700)};
    }

    &:active {
      background-color: ${Colours('errorRed', 500)};
    }
  }

  &:disabled {
    background-color: ${Colours('neutral', 50)};
    color: ${Colours('neutral', 300)};
    border: 1px solid ${Colours('neutral', 50)};
  }
`

export const link = css`
  color: ${Colours('colbalt', 400)};
  padding: 0;
  height: auto;
`

export const linkDisabled = css`
  background-color: ${Colours('neutral', 50)};
  color: ${Colours('neutral', 300)};
  pointer-events: none;
`
