import { isProductionTestingAccount } from '@helpers/auth'
import {
  isOrganisationSelfServe,
  isPodOnboardingGroup
} from '@helpers/organisations'
import { Organisation } from '@typings/graphql/graphql'

export const showHeaderButton = (
  email: string,
  organisation: Organisation
): boolean => {
  return (
    isOrganisationSelfServe(organisation) ||
    isProductionTestingAccount(email) ||
    isPodOnboardingGroup(organisation, email) ||
    isOrganisationSelfServe(organisation)
  )
}
