import { AnimatePresence, motion } from 'framer-motion'
import { useContext } from 'react'

import { ExpandedViewContext } from '@root/src/contexts/expanded_view/provider'

import { TRANSITION } from './constants'
import * as styles from './styles'

const ExpandedView = () => {
  const { currentView } = useContext(ExpandedViewContext)

  const renderExpandedViewContent = () => {
    const fadeOut = { x: 600 }
    const fadeIn = { x: 0 }

    return (
      <motion.div
        initial={fadeOut}
        animate={fadeIn}
        exit={fadeOut}
        transition={TRANSITION}
        style={styles.animationStyles}
      >
        <div
          data-testid='expanded-view'
          css={styles.expandedView}
        >
          {currentView}
        </div>
      </motion.div>
    )
  }

  const renderExpandedView = () => {
    return (
      <AnimatePresence>
        {currentView && renderExpandedViewContent()}
      </AnimatePresence>
    )
  }

  return renderExpandedView()
}

export default ExpandedView
