import { C } from '@thebeansgroup/ui/colours-BKNKZzSh'
import Typography from '@thebeansgroup/ui/typography'

import { NullableReactElement } from '@typings/utils'

import { STYLES, TEXT_COLOURS } from './constants'
import { TBadgeProps } from './types'

const Badge = ({
  children = '-',
  type = 'greyLight',
  variant = 'footnote',
  icon
}: TBadgeProps): React.ReactElement => {
  const renderIcon = (): NullableReactElement => {
    if (!icon) return null

    return icon
  }
  return (
    <div
      className={`${STYLES[type]} rounded-[5px] p-[2px] px-[8px] w-fit whitespace-nowrap flex-center gap-2`}
    >
      {renderIcon()}
      <Typography
        variant={variant}
        component='p'
        colour={TEXT_COLOURS[type] as C}
      >
        {children}
      </Typography>
    </div>
  )
}

export default Badge
