import { BADGE_TYPE } from '@components/badge/constants'

export const SELECTED_ORG_TEST_ID = 'selected-organisation'
export const ORG_MENU_TEST_ID = 'organisation-selector-menu'

export const PRICING_TIER_MAPPING = {
  Starter: BADGE_TYPE.STARTER,
  Growth: BADGE_TYPE.GROWTH,
  Managed: BADGE_TYPE.ENTERPRISE
}
