import { gql } from '@apollo/client'

import {
  CPC_ORG,
  NON_AFFJET_ORG,
  MANUAL_ORG,
  AFFJET_ORG,
  CODE_TRACK_PRO_ORG,
  CPV_ORG,
  CPA_ORG
} from '@constants/organisations'

export const DASHBOARD_CLASSNAME = 'quicksight-embedding-iframe'

export const GET_DASHBOARD_QUERY = gql`
  query DashboardQuery($dashboardId: String!) {
    cognitoViewer {
      quicksightDashboard(dashboardId: $dashboardId) {
        url
      }
    }
  }
`

export const QUICKSIGHT_CLICK_SCHEMA =
  'iglu:com.studentbeans/quicksight_dashboard_click/jsonschema/1-0-0'

export const SHOULD_DASHBOARD_TYPE_SHOW = {
  [NON_AFFJET_ORG]: true,
  [CPC_ORG]: true,
  [MANUAL_ORG]: true,
  [AFFJET_ORG]: true,
  [CODE_TRACK_PRO_ORG]: true,
  [CPV_ORG]: true,
  [CPA_ORG]: true
}

export const DASHBOARD_IDS_BY_TYPE = {
  [NON_AFFJET_ORG]: 'f506948a-6211-4ef9-99e8-20d8476e36ce',
  [CPC_ORG]: '536742a9-6d44-47e4-bf8d-77c7c68d78a9',
  [MANUAL_ORG]: 'f506948a-6211-4ef9-99e8-20d8476e36ce',
  [AFFJET_ORG]: '3ac3fd12-ce20-4077-ac93-ad438eb61dfe',
  [CODE_TRACK_PRO_ORG]: 'a47b503d-fa54-48dc-b18d-caa7a96f6fcf',
  [CPV_ORG]: '872999d1-396c-4ee3-b30a-1361540e9554',
  [CPA_ORG]: '563e1cbd-e6d2-4cd9-9917-1db41f6c3d4e'
}

export const INTERNAL_DASHBOARD_ERROR =
  "Our data is undergoing maintenance. We're really sorry for this inconvenience, but we are working hard to get this back up and running ASAP. You will be able to view your data again by March 4. If you require support, please do not hesitate to reach out to your Customer Support executive."

export const FALLBACK_EMBED_ERROR = 'There was an error embedding the dashboard'
export const EMPTY_ANALYTICS = {
  TITLE: 'Nothing to see here yet',
  DESCRIPTION:
    "You'll see your offer performance data and user insights once your discount program is live",
  BUTTON_TEXT: 'Go to discounts'
}
