import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import {
  mother,
  brother,
  brotherAndAbove
} from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

import {
  EXPANDED_VIEW_WIDTH,
  EXPANDED_VIEW_PAGE_PADDING
} from '@components/expanded_view/constants'
import { EXPANDED_VIEW_ANIMATION_TIME } from '@constants/animation'
import { NAVBAR_WIDTH } from '@constants/layout'

export const pageLayout = css`
  width: 100%;
  background-color: ${Colours('neutral', 10)};
  display: flex;
  position: relative;

  ${brotherAndAbove} {
    width: calc(100% - ${NAVBAR_WIDTH});
  }
`

export const pageContentDefaultStyles = css`
  width: 100%;
  transition: width ${EXPANDED_VIEW_ANIMATION_TIME}ms ease-in-out;
  padding: 33px 20px;
  overflow: auto;

  ${mother} {
    padding: 40px;
  }

  ${brother} {
    padding: 25px;
  }
`

export const smallPageContent = css`
  transition: width ${EXPANDED_VIEW_ANIMATION_TIME}ms ease-in-out;
  width: calc(100% - ${EXPANDED_VIEW_WIDTH}px - ${EXPANDED_VIEW_PAGE_PADDING});
  height: 100%;
  overflow-y: auto;
`

export const card = css`
  width: 100%;
  background-color: ${Colours('white')};
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgba(23, 27, 30, 0.1);
  height: auto;
  padding: 25px;
  margin-top: 25px;
  border: 1px solid ${Colours('grey', 100)};
`

export const minimalStyling = css`
  padding-top: 25px;
  overflow: auto;
  position: relative;
`

export const headerContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
